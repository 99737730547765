import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Link,
  withStyles,
} from '@material-ui/core'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { menuStyles } from './menu.styles'

export const MenuComponent = withStyles(menuStyles)(
  ({ classes, items, isMobileWidth, mobileView }) => {
    const history = useHistory()
    const [drawerOpen, setDrawerOpen] = useState(false)

    const handleNavigate = (item) => {
      setDrawerOpen(!drawerOpen)
      history.push({
        pathname: item.path,
        state: { cleanup: true },
      })
    }

    const toggleDrawer = (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      setDrawerOpen(!drawerOpen)
    }

    const MenuItemCollapsable = ({ item }) => {
      const [anchorEl, setAnchorEl] = React.useState(null)

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
      }

      const handleClose = () => {
        setAnchorEl(null)
      }

      return (
        <>
          <MenuItem
            classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
            selected={item.children.some((item) => item.path === history.location.pathname)}
            onClick={handleClick}
          >
            {item.label}
          </MenuItem>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItems items={item.children} />
          </Menu>
        </>
      )
    }

    const MenuItems = ({ items }) =>
      items.map((item, i) => (
        <MenuItem
          key={i}
          selected={item.path === history.location.pathname}
          onClick={() => handleNavigate(item)}
          className={classes.menuItem}
        >
          {item.url ? (
            <Link underline="none" href={item.url} color="inherit" target="_blank" rel="noopener">
              {item.label}
            </Link>
          ) : (
            item.label
          )}
        </MenuItem>
      ))
    return isMobileWidth || mobileView ? (
      <>
        <IconButton onClick={toggleDrawer}>
          <MenuOutlinedIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <Box p={2}>
            <Box mb={1}>
              <IconButton onClick={toggleDrawer}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Box>
            {items.map((item, i) => (
              <Box key={i}>
                {item.children && item.children.length ? (
                  <Box key={i}>
                    <Box key={i} pb={1} fontSize={20}>
                      <MenuItem
                        style={{ fontWeight: 'bolder' }}
                        classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                        selected={item.children.some(
                          (item) => item.path === history.location.pathname
                        )}
                      >
                        {item.label}
                      </MenuItem>
                    </Box>
                    <Box ml={4} style={{ color: '#444' }}>
                      <MenuItems items={item.children} />
                    </Box>
                  </Box>
                ) : (
                  <MenuItem
                    key={i}
                    selected={item.path === history.location.pathname}
                    onClick={() => handleNavigate(item)}
                    classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                  >
                    {item.label}
                  </MenuItem>
                )}
              </Box>
            ))}
          </Box>
        </Drawer>
      </>
    ) : (
      <MenuList className={classes.horizontal}>
        {items.map((item, i) => (
          <Box key={i}>
            {item.children && item.children.length ? (
              <MenuItemCollapsable item={item} />
            ) : (
              <MenuItem
                key={i}
                selected={item.path === history.location.pathname}
                onClick={() => handleNavigate(item)}
                classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
              >
                {item.label}
              </MenuItem>
            )}
          </Box>
        ))}
      </MenuList>
    )
  }
)
