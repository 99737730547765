import React, { createContext, useContext } from 'react'
import { useConceptosRetributivosReducer } from '../hooks/conceptos-retributivos-reducer.hook'

const ConceptosRetributivos = createContext()

export const ConceptosRetributivosContextProvider = ({ children }) => {
  const reducer = useConceptosRetributivosReducer()
  return <ConceptosRetributivos.Provider value={reducer}>{children}</ConceptosRetributivos.Provider>
}

export const useConceptosRetributivosContext = () => useContext(ConceptosRetributivos)
