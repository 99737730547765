import React, { useMemo, useEffect, useCallback } from 'react'
import { withStyles, Tooltip, Icon, Typography, Box } from '@material-ui/core'
import { compatibleCampaingsTableStyles } from './compatible-campaings-table.styles'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse } from 'qs'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '..'
import {
  COMPATIBLE_CAMPAIGNS_TABLE_COLUMNS,
  DEFAULT_QUERY,
} from './compatible-campaigns-table.constants'
import { campaignsTableStyles } from '../../features/campaigns/components/table/campaigns-table.styles'
import { useCampaignsContext } from '../../features/campaigns/contexts'
import { campaignsDataPreparation } from '../../features/campaigns/logics'
import { CampaignStateSwitch } from '../../features/campaigns/components/table-actions'
import { CampaignsFilter } from '../../features/campaigns'
import { current } from '@reduxjs/toolkit'
const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const CampaignsTable = ({ readOnly }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const combos = useSelector((state) => state.combos)
  const {
    data,
    pagination: { total_campanas: totalEntries },
    loading,
    search,
    tableSelection,
    // onClickTableRow,
    setSelectedTableRows,
    getCampanasCompatiblesTableData,
  } = useCampaignsContext()
  const handleSelectRows = (rows, row) => {
    if (readOnly) return
    setSelectedTableRows(rows)
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...DEFAULT_QUERY,
      ...urlSearchParams,
    }
    getCampanasCompatiblesTableData(searchParams)
  }, [getCampanasCompatiblesTableData, location.search])

  const formattedColumns = useMemo(
    () =>
      COMPATIBLE_CAMPAIGNS_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({
            id: `pages.compatible.campaigns.table.columns.${column.field}.title`,
          }),
          cellStyle: {
            ...campaignsTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return campaignsDataPreparation({ data, combos, intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        if (!row.tableData) row.tableData = {}
        row.tableData['checked'] = true
      }
      return row
    })
  }, [data, combos, tableSelection]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = {
    toolbar: false,
    selection: true,
    showSelectAllCheckbox: false,
    headerStyle: campaignsTableStyles.header,
    tableLayout: 'fixed',
    draggable: false,
    selectionProps: (rowData) => ({
      disabled: readOnly ? true : false,
    }),
  }
  // eslint-disable-line react-hooks/exhaustive-deps

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'state' ? <CampaignStateSwitch {...props} /> : null}
        </MTableCell>
      ),
    }),
    []
  )
  // const handleRowClick = useCallback((_, row) => {
  // onClickTableRow(row['id'])
  // }, [])

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      // onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}

export const CompatibleCampaingsTable = withStyles(compatibleCampaingsTableStyles)(
  ({
    classes,
    readOnly = false,
    value = '',
    decimalScale = undefined,
    fixedDecimalScale = false,
    maxValue = 0,
    units = null,
    currentCampanasDetailPage,
    ...props
  }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'
    const tooltip = useMemo(() => {
      return props.tooltip ? (
        <span style={{ fontSize: 16, marginLeft: 4 }}>
          <Tooltip
            placement="top"
            title={
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {props.tooltip}
              </Typography>
            }
          >
            <Icon fontSize="inherit">help_outline</Icon>
          </Tooltip>
        </span>
      ) : null
    }, [props.tooltip])

    return (
      <>
        {/* {props.title ? (
          <Typography
            variant={`subtitle${typographyVariant}`}
            color="textSecondary"
            className={classes.title}
          >
            {props.title + (tooltip ? tooltip : '')}
          </Typography>
        ) : null} */}
        <Box>
          <Box mb={1}>
            <CampaignsFilter isCampanasCompatibles={true} />
          </Box>
          <CampaignsTable readOnly={readOnly} />
        </Box>
      </>
    )
  }
)
