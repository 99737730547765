export const SIR_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_APPLICATION: 'SET_APPLICATION',
  SET_EVENTS: 'SET_EVENTS',
  SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
  SET_DETAIL_FAILURE: 'SET_DETAIL_FAILURE',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
  SELECT_SIR_APPLICATION: 'SELECT_SIR_APPLICATION',
  SET_BUDGET: 'SET_BUDGET',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_COMPANY_EDIT_MODE: 'SET_COMPANY_EDIT_MODE',
  SET_USER_EDIT_MODE: 'SET_USER_EDIT_MODE',
  SET_FILTERS: 'SET_FILTERS',
  DELETE_FILTER: 'DELETE_FILTER',
  UPDATE_FILTER: 'UPDATE_FILTER',
}

export const SIR_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: { totalSolicitudes: 0 },
  loading: false,
  detailLoading: true,
  detailError: false,
  msLoaded: false,
  error: false,
  application: {},
  events: [],
  tableSelection: [],
  editMode: false,
  savedFilters: [],
}

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
