import {
  TextInput,
  CheckListInput,
  DateInput,
  NumberInput,
  SelectInput,
  FormSectionTitle,
  RadioInput,
} from '../../../../ui'
import { LocSelectInput, ConceptosRetributivosSelectInput } from '../custom-inputs'
import { loadLocaleText } from '../../../../core/utils/textLocaleUtils'

const GRID_ITEM_FULL = {
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
}

const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}

const mock_origen = {
  data: [
    { key: 'instalador', value: 'Instalador', id: '1' },
    { key: 'gremio', value: 'Asociación', id: '2' },
  ],
}

const mock_tipo_vivienda = {
  data: [
    { key: 'unifamiliar', value: 'Unifamiliar', id: '1' },
    { key: 'plurifamiliar', value: 'Plurifamiliar', id: '2' },
  ],
}

const mock_tipo_cliente = {
  data: [
    { key: 'activo', value: 'Activo', id: '1' },
    { key: 'nuevo', value: 'Nuevo', id: '2' },
  ],
}

const computeDate = (date, days) => {
  if (!date) return
  const daysValue = parseInt(days || '0')
  const result = new Date(date)
  result.setDate(result.getDate() + daysValue)
  return result
}

const MOMENTO_PAGO_PUESTA_SERVICIO = 'PUESTA_SERVICIO'

export const INPUTS_CONFIG = ({ data, combos, intl, onChange, readOnly }) => {
  const localeText = loadLocaleText('pages.campaigns.detail.fields', intl)
  const DEFAULT_PROPS = { onChange, readOnly, fontSize: 'small', required: true }
  const has_momento_pago = (conceptos, momento_pago) =>
    !conceptos?.some((concept) => concept.momento_pago === momento_pago)

  return {
    NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['nombre'],
      title: localeText('nombre'),
      name: 'nombre',
    },
    CODIGO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['codigo'],
      title: localeText('codigo'),
      name: 'codigo',
      required: true,
      readOnly: true,
    },
    CONCEPTOS_RETRIBUTIVOS: {
      ...DEFAULT_PROPS,
      component: ConceptosRetributivosSelectInput,
      value: data['conceptos_retributivos'] || [],
      conceptosRetributivos: combos['conceptos_retributivos']?.data,
      title: localeText('concepto_retributivo'),
      name: 'conceptos_retributivos',
      required: false,
      formState: data,
    },
    DESCUENTO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['descuento'],
      title: localeText('descuento.public.offer'),
      name: 'descuento',
      units: '€',
      required: false,
    },
    INGRESO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['ingreso'],
      title: localeText('ingreso'),
      name: 'ingreso',
      units: '€',
      required: false,
    },
    FINANCIACION: {
      ...DEFAULT_PROPS,
      component: RadioInput,
      // fullWidth: true,
      value: data['financiacion'],
      values: combos['binaryValues']?.data,
      title: localeText('financiacion.public.offer'),
      name: 'financiacion',
      required: true,
      isColumn: true,
    },
    MESES_FINANCIACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['meses_financiacion'],
      title: localeText('meses_financiacion'),
      name: 'meses_financiacion',
      hidden: data['financiacion'] === '00',
      // required: data['financiacion'] === '01',
      required: false,
      inputProps: { maxLength: 3 },
    },
    LIMITE_FINANCIACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['limite_financiacion'],
      title: localeText('limite_financiacion'),
      name: 'limite_financiacion',
      units: '€',
      // required: data['financiacion'] === '01',
      required: false,
      hidden: data['financiacion'] === '00',
      inputProps: { maxLength: 8 },
    },
    ELEMENTOS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['elementos'],
      values: combos['productos']?.data,
      title: localeText('elementos'),
      name: 'elementos',
      cKeyType: 'int',
      keyField: 'id',
      valueField: 'nombre',
      required: !data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
      hidden: data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
    },
    TARIFAS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['tarifas'],
      values: combos['tarifa']?.data,
      title: localeText('tariffs'),
      name: 'tarifas',
      cKeyType: 'string',
      keyField: 'id',
      valueField: 'nombre',
      required: data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
      hidden: !data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
    },
    MERCADOS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['mercados'],
      values: combos['tipo_mercado_campañas']?.data,
      title: localeText('mercados'),
      name: 'mercados',
      cKeyField: 'id',
      cKeyType: 'int',
      keyField: 'id',
      valueField: 'nombre',
    },
    FECHA_PUBLICACION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechapublicacion'],
      title: localeText('fechapublicacion'),
      name: 'fechapublicacion',
      maxDate: data['fechainicio'],
      required: false,
    },
    TIEMPO_GRABACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['tiempograbacion'],
      title: localeText('tiempograbacion'),
      name: 'tiempograbacion',
      decimalScale: 0,
      units: 'días',
      required: false,
      inputProps: { maxLength: 3 },
    },
    FECHA_FIN_VIGENCIA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechafinvigencia'],
      title: localeText('fechafinvigencia'),
      name: 'fechafinvigencia',
      minDate: data['fechainicio'],
      required: false,
    },
    FECHA_INICIO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechainicio'],
      title: localeText('fechainicio'),
      name: 'fechainicio',
      maxDate: computeDate(data['fechafinvigencia'], data['tiempograbacion']),
      required: false,
    },
    FECHA_FIN: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: computeDate(data['fechafinvigencia'], data['tiempograbacion']),
      title: localeText('fechafin'),
      name: 'fechafin',
      minDate: data['fechainicio'],
      readOnly: true,
    },
    ORIGEN: {
      ...DEFAULT_PROPS,
      component: RadioInput,
      value: data['origen'],
      values: mock_origen?.data,
      title: localeText('origen'),
      name: 'origen',
      required: true,
    },
    PLAZO_PUESTA_SERVICIO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['plazo_puesta_servicio'],
      title: localeText('plazo_puesta_servicio'),
      name: 'plazo_puesta_servicio',
      hidden: has_momento_pago(data['conceptos_retributivos'], MOMENTO_PAGO_PUESTA_SERVICIO),
      required: false,
      units: 'días'
    },
    FECHA_INICIO_PUESTA_SERVICIO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_inicio_puesta_servicio'],
      title: localeText('fecha_inicio_puesta_servicio'),
      name: 'fecha_inicio_puesta_servicio',
      hidden: has_momento_pago(data['conceptos_retributivos'], MOMENTO_PAGO_PUESTA_SERVICIO),
      maxDate: data['fecha_fin_puesta_servicio'],
      maxDateMessage: localeText('fecha_inicio_puesta_servicio', 'maxDateMessage'),
      required: false,
    },
    FECHA_FIN_PUESTA_SERVICIO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_fin_puesta_servicio'],
      title: localeText('fecha_fin_puesta_servicio'),
      name: 'fecha_fin_puesta_servicio',
      hidden: has_momento_pago(data['conceptos_retributivos'], MOMENTO_PAGO_PUESTA_SERVICIO),
      minDate: data['fecha_inicio_puesta_servicio'],
      minDateMessage: localeText('fecha_fin_puesta_servicio', 'minDateMessage'),
      required: false,
    },
    TIPO_GAS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['tipo_gas'],
      values: combos['tipo_gas_campana']?.data,
      title: localeText('tipo_gas'),
      name: 'tipo_gas',
      required: true,
    },
    TIPO_VIVIENDA: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['tipo_vivienda'],
      values: mock_tipo_vivienda?.data,
      title: localeText('tipo_vivienda'),
      name: 'tipo_vivienda',
      required: true,
    },
    // OP_CHECK: {
    //   ...DEFAULT_PROPS,
    //   component: CheckboxComponent,
    //   value: data['is_op'],
    //   title: txt('is_op'),
    //   label: txt('is_op_label'),
    //   name: 'is_op',
    //   required: false,
    //   hidden: data['origen'] === 'cliente' /*true*/,
    // },
    COMUNIDADES: {
      ...DEFAULT_PROPS,
      component: LocSelectInput,
      value: data['comunidades'] || [],
      ccaaValues: combos['cod_zona_zeus']?.data,
      provValues: combos['provincias']?.data,
      guildValues: data.origen === 'gremio' ? combos['gremios']?.data : [],
      formState: data,
      name: 'comunidades',
      required: true,
    },
    DISTANCIA_ACOMETIDA: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['distancia_acometida'],
      title: localeText('distancia_acometida'),
      name: 'distancia_acometida',
      required: false,
      inputProps: { maxLength: 6 },
    },
    DURACION_CAMPANA_TITLE: {
      ...DEFAULT_PROPS,
      component: FormSectionTitle,
      title: localeText('duracion_campana_title'),
      name: 'duracion_campana_title',
      required: false,
    },
    TIPO_CLIENTE: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['tipo_cliente'],
      values: mock_tipo_cliente?.data,
      title: localeText('tipo_cliente'),
      name: 'tipo_cliente',
      required: false,
    },
    POTENCIA_INSTALADA: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      value: data['potencia_instalada'],
      values: combos['rangos_potencia']?.data,
      title: localeText('potencia_instalada'),
      name: 'potencia_instalada',
      required: false,
    },
  }
}

export const STEP_1_FORM_CONFIG = [
  {
    title: 'Datos generales',
    fields: [
      { ref: 'NOMBRE', conf: GRID_ITEM_LARGE },
      { ref: 'CODIGO', conf: GRID_ITEM_LARGE },
      { ref: 'CONCEPTOS_RETRIBUTIVOS', conf: GRID_ITEM_FULL },
      { ref: 'FINANCIACION', conf: GRID_ITEM_FULL },
      { ref: 'MESES_FINANCIACION', conf: GRID_ITEM_LARGE },
      { ref: 'LIMITE_FINANCIACION', conf: GRID_ITEM_LARGE },
      { ref: 'ORIGEN', conf: GRID_ITEM_FULL },
    ],
  },
]

export const STEP_2_FORM_CONFIG = [
  {
    title: 'Datos de la campaña',
    fields: [
      { ref: 'TIPO_VIVIENDA', conf: GRID_ITEM_FULL },
      { ref: 'MERCADOS', conf: GRID_ITEM_LARGE },
      { ref: 'ELEMENTOS', conf: GRID_ITEM_LARGE },
      { ref: 'TARIFAS', conf: GRID_ITEM_LARGE },
      { ref: 'DURACION_CAMPANA_TITLE', conf: GRID_ITEM_FULL },
      { ref: 'FECHA_INICIO', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_PUBLICACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN_VIGENCIA', conf: GRID_ITEM_LARGE },
      { ref: 'TIEMPO_GRABACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_INICIO_PUESTA_SERVICIO', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN_PUESTA_SERVICIO', conf: GRID_ITEM_LARGE },
      { ref: 'PLAZO_PUESTA_SERVICIO', conf: GRID_ITEM_LARGE },
    ],
  },
]

export const STEP_3_FORM_CONFIG = [
  {
    title: 'Datos vivienda',
    fields: [
      { ref: 'TIPO_GAS', conf: GRID_ITEM_FULL },
      { ref: 'DISTANCIA_ACOMETIDA', conf: GRID_ITEM_LARGE },
      { ref: 'TIPO_CLIENTE', conf: GRID_ITEM_FULL },
      // { ref: 'NUMERO_VIVIENDAS_FINCA', conf: GRID_ITEM_FULL },
      // { ref: 'NUMERO_VIVIENDAS_FINCA_DESDE', conf: GRID_ITEM_LARGE },
      // { ref: 'NUMERO_VIVIENDAS_FINCA_HASTA', conf: GRID_ITEM_LARGE },
      { ref: 'POTENCIA_INSTALADA', conf: GRID_ITEM_LARGE },
    ],
  },
]

export const STEP_4_FORM_CONFIG = [
  {
    title: 'Zona de actuación',
    fields: [{ ref: 'COMUNIDADES', conf: GRID_ITEM_FULL }],
  },
]

export const FORM_CONFIG = [
  {
    fields: [
      { ref: 'NOMBRE', conf: GRID_ITEM_LARGE },
      { ref: 'CODIGO', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Oferta económica',
    fields: [
      { ref: 'DESCUENTO', conf: GRID_ITEM_LARGE },
      // { ref: 'INGRESO', conf: GRID_ITEM_LARGE },
      // { ref: 'FINANCIACION', conf: GRID_ITEM_FULL },
      // { ref: 'MESES_FINANCIACION', conf: GRID_ITEM_LARGE },
      // { ref: 'LIMITE_FINANCIACION', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Mercados y productos',
    fields: [
      { ref: 'MERCADOS', conf: GRID_ITEM_LARGE },
      { ref: 'ELEMENTOS', conf: GRID_ITEM_LARGE },
      { ref: 'TARIFAS', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Tipo de gas',
    fields: [{ ref: 'TIPO_GAS', conf: GRID_ITEM_LARGE }],
    divider: true,
  },
  {
    title: 'Duración de la Oferta Pública',
    fields: [
      { ref: 'FECHA_INICIO', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_PUBLICACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN_VIGENCIA', conf: GRID_ITEM_LARGE },
      { ref: 'TIEMPO_GRABACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Origen de la Oferta Pública',
    fields: [
      { ref: 'ORIGEN', conf: GRID_ITEM_LARGE },
      // { ref: 'OP_CHECK', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Zona de actuación',
    fields: [{ ref: 'COMUNIDADES', conf: GRID_ITEM_FULL }],
  },
]
