import { SIR_ACTIONS_TYPES } from './sir-reducer.constants'

export const sirReducer = (state, { type, payload }) => {
  switch (type) {
    case SIR_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case SIR_ACTIONS_TYPES.SET_APPLICATION:
      return {
        ...state,
        application: payload,
        detailLoading: false,
      }
    // case SIR_ACTIONS_TYPES.SET_BUDGET:
    //   return {
    //     ...state,
    //     application: {
    //       ...state.application,
    //       presupuesto_sir: { ...state.application.presupuesto_sir, ...payload },
    //     },
    //     detailLoading: false,
    //   }
    case SIR_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case SIR_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }
    case SIR_ACTIONS_TYPES.SET_EVENTS:
      return {
        ...state,
        events: payload,
      }
    case SIR_ACTIONS_TYPES.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case SIR_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }
    case SIR_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    case SIR_ACTIONS_TYPES.SET_EDIT_MODE:
      return {
        ...state,
        editMode: payload,
      }
    case SIR_ACTIONS_TYPES.SET_COMPANY_EDIT_MODE:
      return {
        ...state,
        editCompanyMode: payload,
      }
    case SIR_ACTIONS_TYPES.SET_USER_EDIT_MODE:
      return {
        ...state,
        editUserMode: payload,
      }
    case SIR_ACTIONS_TYPES.SET_FILTERS:
      return {
        ...state,
        savedFilters: payload,
      }
    case SIR_ACTIONS_TYPES.DELETE_FILTER:
      return {
        ...state,
        savedFilters: state.savedFilters.filter((el) => el.key !== payload),
      }
    case SIR_ACTIONS_TYPES.UPDATE_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters, payload],
      }
    default:
      throw new Error('unknown action')
  }
}
