const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const conceptosRetributivosTableStyles = {
  header: {
    fontSize: 14,
    paddingRight: 10,
    color: '#1a171b',
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    height: 40,
    fontSize: 14,
    color: '#63666a',
    paddingRight: 10,
    paddingLeft: 15,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root: {
    width: '100%',
    // margin: styledBy('spaceless', {
    //   undefined: '12px 0',
    //   false: '12px 0',
    //   true: 0,
    // }),
  },
  title: {
    fontFamily: 'FSEmeric-Medium',
    // fontSize: 16,
    '&+*': { marginTop: 0, width: '100%' },
  },
  input: {
    height: styledBy('multiline', {
      undefined: '48px',
      false: '48px',
      true: 'auto',
    }),
  },
}
