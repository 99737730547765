import React from 'react'
import EditIcon from '@material-ui/icons/Edit'

import { useConceptosRetributivosContext } from '../../contexts'
import { Tooltip } from "@material-ui/core";

export const ConceptosRetributivosEdit = ({ classes, props }) => {
  const { onClickTableRow } = useConceptosRetributivosContext()
  const conceptoRetributivoId = props.rowData.id
  const handleEdit = (e) => {
    onClickTableRow(conceptoRetributivoId)
  }
  if (props.rowData.is_editable) return <EditIcon onClick={handleEdit} style={{ cursor: 'pointer', fontSize: '1.8em' }} />
  return <Tooltip title="No se puede editar el concepto debido a que está asignado a campañas"><EditIcon style={{ fontSize: '1.8em', color: "lightgray" }} /></Tooltip>
}
