import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, FormPanel } from '../../../../ui'
import { INSTALLER_EDIT_FIELDS, INSTALLER_EDIT_CONFIG } from './edit-installer-form.constants'
import { isValidEmail } from '../../../../core/utils'
import { feedback } from '../../../../core/feedback'

export const EditInstallerForm = ({ close, user, updateClientInfo }) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useSelector((state) => state.combos)

  const [formState, setFormState] = useState(user)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isValidEmail(formState['email'])) {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.1' }))
    }
    setLoading(true)
    const payload = {
      nombre: formState.nombre,
      apellidos: formState.apellidos,
      tipo_identificador: formState.tipo_identificador,
      identificador: formState.identificador,
      email: formState.email,
      telefono: formState.telefono,
    }
    updateClientInfo(user.id, payload)
      .then(() => {
        feedback('success', fm({ id: 'pages.application.detail.dialogs.editClient.feedback.5' }))
        setLoading(false)
        close()
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fieldList = useMemo(() => {
    return INSTALLER_EDIT_FIELDS({ combos, intl, onChange: handleChange })
  }, [combos, formState]) // eslint-disable-line react-hooks/exhaustive-deps

  const block = useMemo(() => {
    return INSTALLER_EDIT_CONFIG({ intl })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit}>
      <FormPanel {...{ data: formState, combos, intl, block, fieldList }} />
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
        <Box width={190} mb={[3, 0]} p="0 5px">
          <ThemeButton onClick={close} variant="outlined" fullWidth disabled={loading}>
            {fm({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box width={190} p="0 5px">
          <ThemeButton type="submit" color="primary" fullWidth loading={loading} disabled={loading}>
            {fm({ id: 'global.save' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
