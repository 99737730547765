export const CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_CONCEPTO_RETRIBUTIVO: 'SET_CONCEPTO_RETRIBUTIVO',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
  SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
  SET_DETAIL_FAILURE: 'SET_DETAIL_FAILURE',
  // SET_EDIT_MODE: 'SET_EDIT_MODE',
}

export const CONCEPTOS_RETRIBUTIVOS_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: {},
  loading: false,
  detailLoading: false,
  detailError: false,
  msLoaded: false,
  error: false,
  conceptoRetributivo: {},
  tableSelection: [],
  editMode: false,
}
