import React from 'react'
import { withStyles, Box, Typography, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { offersPanelStyles } from './offers-panel.style'
import {
  URL_OFFER_HOT_WATER,
  URL_OFFER_HEATING,
  URL_OFFER_BUSINESS_TARIFF_3_2,
  URL_OFFER_BUSINESS_TARIFF_3_3,
  URL_OFFER_DECENTRALIZATION,
  URL_OFFER_TRANSFORMATION,
  HOT_WATER_IMG,
  BUSINESS_IMG,
  HEATER_IMG,
} from '../../constants/offers.constants'

export const OffersPanel = withStyles(offersPanelStyles)(({ classes }) => {
  const intl = useIntl()

  const openLinkHandler = (link) => {
    window.open(link)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" textAlign="center" mb={3} mt={3}>
        <Typography variant="h6" color="primary">
          {intl.formatMessage({ id: 'pages.offers.headLine' })}
        </Typography>
      </Box>
      <Grid container justifyContent="center" spacing={10}>
        <Grid item l={4}>
          <Box display="flex" flexDirection="column" alignItems="Center">
            <Typography style={{ fontWeight: 'bold' }} variant="h4" color="primary">
              {intl.formatMessage({ id: 'pages.offers.homeTitle' })}
            </Typography>
            <Box mt={2}>
              <img className={classes.img} src={HOT_WATER_IMG} alt="" width="300" />
            </Box>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span onClick={() => openLinkHandler(URL_OFFER_HOT_WATER)} className={classes.link}>
                {intl.formatMessage({ id: 'pages.offers.hotWaterOffer' })}
              </span>
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span onClick={() => openLinkHandler(URL_OFFER_HEATING)} className={classes.link}>
                {intl.formatMessage({ id: 'pages.offers.heatingOffer' })}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item l={4}>
          <Box display="flex" flexDirection="column" alignItems="Center">
            <Typography style={{ fontWeight: 'bold' }} variant="h4" color="primary">
              {intl.formatMessage({ id: 'pages.offers.businessTitle' })}
            </Typography>
            <Box mt={2}>
              <img className={classes.img} src={BUSINESS_IMG} alt="" width="300" />
            </Box>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span
                onClick={() => openLinkHandler(URL_OFFER_BUSINESS_TARIFF_3_2)}
                className={classes.link}
              >
                {intl.formatMessage({ id: 'pages.offers.businessOffer.3.2' })}
              </span>
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span
                onClick={() => openLinkHandler(URL_OFFER_BUSINESS_TARIFF_3_3)}
                className={classes.link}
              >
                {intl.formatMessage({ id: 'pages.offers.businessOffer.3.3' })}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item l={4}>
          <Box display="flex" flexDirection="column" alignItems="Center">
            <Typography style={{ fontWeight: 'bold' }} variant="h4" color="primary">
              {intl.formatMessage({ id: 'pages.offers.heatingTitle' })}
            </Typography>
            <Box mt={2}>
              <img className={classes.img} src={HEATER_IMG} alt="" width="300" />
            </Box>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span
                onClick={() => openLinkHandler(URL_OFFER_DECENTRALIZATION)}
                className={classes.link}
              >
                {intl.formatMessage({ id: 'pages.offers.decentralization' })}
              </span>
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              <span
                onClick={() => openLinkHandler(URL_OFFER_TRANSFORMATION)}
                className={classes.link}
              >
                {intl.formatMessage({ id: 'pages.offers.transformation' })}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* <Box display="flex" flexDirection="column" alignSelf="flex-start" mt={4} ml={5}>
        <List style={{ padding: '0' }}>
          <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
            {intl.formatMessage({ id: 'pages.offers.financingTitle' })}
          </Typography>
          <ListItem style={{ padding: '0' }}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: '1em' }} color="primary" />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
                {intl.formatMessage({ id: 'pages.offers.installerAdhesion' })}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <List style={{ padding: '0' }}>
              <ListItem style={{ padding: '0' }}>
                <Box display="flex" alignItems="center" ml={10}>
                  <ListItemIcon>
                    <FiberManualRecordIcon style={{ fontSize: '1em' }} color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
                      <span
                        onClick={() => openLinkHandler(URL_ADHESION_GUIDE)}
                        className={classes.link}
                      >
                        {intl.formatMessage({ id: 'pages.offers.installerAdhesionGuide' })}
                      </span>
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>
              <ListItem style={{ padding: '0' }}>
                <Box display="flex" alignItems="center" ml={10}>
                  <ListItemIcon>
                    <FiberManualRecordIcon style={{ fontSize: '1em' }} color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
                      <span
                        onClick={() => openLinkHandler(VIDEO_CAIXABANK)}
                        className={classes.link}
                      >
                        {intl.formatMessage({ id: 'pages.offers.caixaBankVideo' })}
                      </span>
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>
              <ListItem style={{ padding: '0' }}>
                <Box display="flex" alignItems="center" ml={10}>
                  <ListItemIcon>
                    <FiberManualRecordIcon style={{ fontSize: '1em' }} color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
                      <span
                        onClick={() => openLinkHandler(URL_CAIXABANK_DOCUMENT)}
                        className={classes.link}
                      >
                        {intl.formatMessage({ id: 'pages.offers.caixaBankAdhesionDocument' })}
                      </span>
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>
            </List>
          </ListItem>
          <ListItem style={{ padding: '0' }}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: '1em' }} color="primary" />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ fontWeight: 'bold' }} variant="h5" color="primary">
                <span
                  onClick={() => openLinkHandler(FINANCIATION_APLICATION_GUIDE)}
                  className={classes.link}
                >
                  {intl.formatMessage({ id: 'pages.offers.clientApplication' })}
                </span>
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box> */}
    </Box>
  )
})
