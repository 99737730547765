export const CAMPAIGNS_COMBOS_IDS = [
  'tipo_mercado_campañas',
  'productos',
  'cod_zona_zeus',
  'provincias',
  'tarifa',
  'tipo_gas_campana',
  'conceptos_retributivos',
  'rangos_potencia',
]
