import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { useApplicationUserPermitsContext } from '../../../pages/applications/contexts'
import { APP_EDITION_OPTIONS } from '../constants'
import { appReducer } from '../reducers'
import { APP_INITIAL_STATE, APP_ACTIONS_TYPES } from '../reducers'
import { useAppCalls } from './app-calls.hook'
import feedback from '../../../core/feedback'

const prepareApplicationModel = (application) => {
  let preparedModel = { ...application }
  if (!!application.presupuesto_exterior) {
    for (let key in application.presupuesto_exterior) {
      preparedModel[`ppto_ext_${key}`] = application.presupuesto_exterior[key]
    }
  }
  return preparedModel
}

export const useAppReducer = () => {
  const [state, dispatch] = useReducer(appReducer, APP_INITIAL_STATE)
  const history = useHistory()
  const { formatMessage: fm } = useIntl()
  const userId = useSelector((state) => state.global.userId)
  const {
    getAppCall,
    putAppCall,
    putAppDocsCall,
    putBudgetCall,
    acceptBudgetCall,
    uploadPaymentFileCall,
    generateBudgetCall,
    sendBudgetCall,
    validatePaymentCall,
    getPaymentInfoCall,
    sendFormCall,
    cancelApplicationCall,
    getAppEventsCall,
    postAppEventCall,
    putClientCall,
    putHoldAppCall,
    requestChangeCall,
    postAddInstallerCall,
    putCandeDevolutionWithoutTaskAppCall,
    deleteInstallerCall,
    getInstallerValuesCall,
    changeInstallerCall,
    postGASPCall,
    putGASPCall,
    putTask,
  } = useAppCalls()
  const { application } = state
  const appId = application.id
  const { canEditApp } = useApplicationUserPermitsContext()

  const changeLoading = (value) =>
    dispatch({
      type: APP_ACTIONS_TYPES.SET_LOADING,
      payload: value,
    })

  const getApplication = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: APP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        getAppCall(id)
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
            dispatch({
              type: APP_ACTIONS_TYPES.SET_LOADING,
              payload: false,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateApplication = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: APP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        putAppCall(appId, payload)
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateApplicationDocs = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: APP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        putAppDocsCall(state.application.id, payload)
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel({
                ...data,
                pendiente_peticionario: state.application?.pendiente_peticionario,
              }),
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateBudget = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: APP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        const budgetId = state.application.presupuesto_exterior.id
        putBudgetCall(budgetId, payload)
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const generateBudget = useCallback(
    () =>
      new Promise((resolve, reject) => {
        dispatch({
          type: APP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        const payload = { solicitud_id: appId }
        generateBudgetCall(payload)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.feedback.2' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendBudget = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        sendBudgetCall(payload)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.feedback.3' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.detail.feedback.4' }))
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptBudget = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const budgetId = state.application.presupuesto_exterior.id
        acceptBudgetCall(budgetId)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.presupuesto.sended' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.detail.presupuesto.notsended' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendPaymentFile = useCallback(
    (model, data) =>
      new Promise((resolve, reject) => {
        uploadPaymentFileCall(model, data)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.comprobante.uploaded' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.detail.comprobante.notuploaded' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changeInstaller = useCallback(
    (payload, id) =>
      new Promise((resolve, reject) => {
        changeInstallerCall(payload, id)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.consult.granconsumo.add.installer.success' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.consult.granconsumo.add.installer.error' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validatePayment = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        validatePaymentCall(id, '112')
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const invalidatePayment = useCallback(
    (application, payload) =>
      new Promise((resolve, reject) => {
        putBudgetCall(application.presupuesto_sir.id, payload)
          .then(() => {
            validatePaymentCall(application.id, '103')
              .then(({ data }) => {
                dispatch({
                  type: APP_ACTIONS_TYPES.SET_APPLICATION,
                  payload: prepareApplicationModel(data),
                })
              })
              .then(() => resolve())
              .catch(() => {
                return reject('Error')
              })
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelApplication = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        cancelApplicationCall(payload)
          .then(() => {
            const search = history.location.state?.prevSearch || ''
            history.push('/applications' + search)
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getInstallerValues = useCallback(
    (name) =>
      new Promise((resolve, reject) => {
        getInstallerValuesCall(name)
          .then((response) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_INSTALLER_VALUE,
              payload: response.data,
            })
            resolve(response)
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getAppEvents = useCallback(
    () =>
      new Promise((resolve, reject) => {
        getAppEventsCall(appId)
          .then(({ data }) => {
            const sortedData = data
              .map((record) => {
                // let responsable = ''
                // if (record.tipo_usuario) {
                //   responsable = fm({
                //     id: `pages.application.detail.history.tableFields.responsable.${record.tipo_usuario}`,
                //   })
                // }
                return {
                  ...record,
                  // responsable: `${record.gestor || record.instalador || '--'} (${responsable})`,
                  responsable: `${record.gestor || record.instalador || '--'}`,
                  mensaje: `${record.mensaje} ${
                    record.descripcion ? `(${record.descripcion})` : ''
                  }`,
                }
              })
              .sort((a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert']))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_EVENTS,
              payload: sortedData,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAppEvent = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postAppEventCall(appId, payload)
          .then(() => {
            getAppEvents()
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateClientInfo = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const clientId = state.application.usuario.id
        putClientCall(clientId, payload)
          .then(() => {
            getApplication(appId)
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const requestChange = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        requestChangeCall(id)
          .then(({ data }) => {
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAddInstaller = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postAddInstallerCall(payload)
          .then((response) => resolve(response))
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteInstaller = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        deleteInstallerCall(appId, payload)
          .then((response) => {
            getApplication(appId)
            resolve(response)
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putOnTask = useCallback(
    (id, payload, application) =>
      new Promise((resolve, reject) => {
        putTask(id, payload)
          .then((response) => {
            getApplication(application.id)
            resolve(response)
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [appId, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const disabledForUser = useCallback(() => {
    if (canEditApp === APP_EDITION_OPTIONS.none) {
      return true
    } else if (
      canEditApp === APP_EDITION_OPTIONS.own &&
      application.gestor_id &&
      userId !== application.gestor_id.toString()
    ) {
      return true
    } else if (canEditApp === APP_EDITION_OPTIONS.own && !application.gestor_id) {
      return true
    } else {
      return false
    }
  }, [canEditApp, application.gestor_id, userId]) // eslint-disable-line react-hooks/exhaustive-deps

  const sendForm = useCallback(
    (payload, id) =>
      new Promise((resolve, reject) => {
        sendFormCall(payload, id)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.comprobante.uploaded' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.detail.comprobante.notuploaded' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPaymentInfo = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getPaymentInfoCall(id)
          .then(({ data }) => {
            feedback('success', fm({ id: 'pages.application.detail.comprobante.uploaded' }))
            dispatch({
              type: APP_ACTIONS_TYPES.SET_APPLICATION,
              payload: prepareApplicationModel(data),
            })
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.detail.comprobante.notuploaded' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postGASP = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postGASPCall(payload)
          .then(() => {
            feedback(
              'success',
              fm({ id: 'pages.application.view.documentViewer.call_success_post.title' })
            )
            getApplication(appId)
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.view.documentViewer.call_error.title' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putGASP = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const gas_pId = state.application.gas_p.id
        putGASPCall(gas_pId, payload)
          .then(() => {
            feedback(
              'success',
              fm({ id: 'pages.application.view.documentViewer.call_success_put.title' })
            )
            getApplication(appId)
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', fm({ id: 'pages.application.view.documentViewer.call_error.title' }))
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putHoldApp = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        putHoldAppCall(id)
          .then(() => {
            feedback('success', 'La solicitud ha vuelto al estado anterior')

            getApplication(appId)
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', 'No ha sido posible volver al estado interior')
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putCancelDevolutuinWithoutTaskdApp = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        putCandeDevolutionWithoutTaskAppCall(id)
          .then(() => {
            feedback('success', 'La solicitud ha sido anula sin devolucion')

            getApplication(id)
          })
          .then(() => resolve())
          .catch(() => {
            feedback('error', 'No ha sido posible anular la solicitud')
            return reject('Error')
          })
      }),
    [state.application, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    changeLoading,
    getApplication,
    updateApplication,
    updateApplicationDocs,
    putCancelDevolutuinWithoutTaskdApp,
    generateBudget,
    updateBudget,
    validatePayment,
    invalidatePayment,
    cancelApplication,
    putHoldApp,
    sendBudget,
    getPaymentInfo,
    sendForm,
    acceptBudget,
    sendPaymentFile,
    getAppEvents,
    postAppEvent,
    updateClientInfo,
    requestChange,
    disabledForUser,
    postAddInstaller,
    deleteInstaller,
    getInstallerValues,
    changeInstaller,
    postGASP,
    putGASP,
    putOnTask,
    ...state,
  }
}
