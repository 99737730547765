import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Grid, CircularProgress, FormHelperText, Icon } from '@material-ui/core'

import { FileUploaderAgnostic, UploadTooltip } from '../../../../ui'
import { toast } from 'react-toastify'
import { ROOT_CERTIFICATES_DOCUMENTS } from '../../constants'

const AttachField = ({ label, data, showErrors, errors = {}, updateInfo, ...restProps }) => {
  const { formatMessage } = useIntl()

  const handleUploadCertificateFile = (info, type) => {
    //Info as it should be submitted to BE
    updateInfo(info)
    //Update info as back would return it so that the file is already displayed on screen
    updateInfo({
      target: {
        name: 'd_' + type + '_id',
        value: '-1',
      },
    })
    updateInfo({
      target: {
        name: 'd_' + type + '_nombre',
        value: info.target.value.name.split('.').slice(0, -1).join('.'),
      },
    })
    updateInfo({
      target: {
        name: 'd_' + type + '_extension',
        value: info.target.value.type,
      },
    })
  }

  const handleFileUploadError = (data) => {
    toast.error(
      <>
        <Icon>error_outlined</Icon>
        <Typography
          variant="body1"
          display="inline"
          style={{ verticalAlign: 'super', marginLeft: 8 }}
        >
          {data.mssg}
        </Typography>
      </>
    )
  }
  if (restProps.field === 'tallo_i' && !data['presupuesto_id']) return null
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box display="flex" flexDirection="horizontal">
          <Typography style={{ marginRight: '0.5em' }} variant="body1">
            {label}
          </Typography>
          <UploadTooltip file={restProps} />
        </Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginLeft: '1em',
            flex: '1 0 25em',
          }}
        >
          <Typography variant="body2">
            {data['d_' + restProps.field + '_id']
              ? data['d_' + restProps.field + '_nombre'] +
              '.' +
              data['d_' + restProps.field + '_extension']
              : null}
          </Typography>
          <FileUploaderAgnostic
            applicationId={data['solicitud_id']}
            clientId={data['client_id']}
            onSuccess={(info) => handleUploadCertificateFile(info, restProps.field)}
            onError={handleFileUploadError}
            name={restProps.field}
            //Comment: we save by field and not by type to locate them easily from the config list
            idName={restProps.field}
            {...restProps}
          >
            {(props) => (
              <span
                className={'MuiButtonBase-root MuiButton-outlined MuiButton-root'}
                style={{ marginLeft: 10 }}
              >
                {props.loading ? (
                  <CircularProgress size={20} />
                ) : (
                  formatMessage({ id: 'pages.certificates.documents.button.add' })
                )}
              </span>
            )}
          </FileUploaderAgnostic>
        </div>
      </Box>
      <Box>
        <FormHelperText error={showErrors && errors[restProps.id]}>
          {showErrors && errors[restProps.id] ? 'Este documento es obligatorio' : null}
        </FormHelperText>
      </Box>
    </>
  )
}

export const CertificateDocuments = ({ documentList, data, updateInfo, showErrors, errors }) => {
  const { formatMessage } = useIntl()
  return (
    <Box>
      <Typography variant="body1" color="primary" paragraph>
        {formatMessage({ id: 'pages.certificates.documents.title' })}
      </Typography>
      <Grid container spacing={10} style={{ marginTop: 20, marginBottom: 20 }}>
        {documentList.map((fieldProps, i) => {
          const label = formatMessage({ id: ROOT_CERTIFICATES_DOCUMENTS + '.' + fieldProps.label })
          return fieldProps.type === 'CB' && !data?.campanas?.campana?.ingreso ? null : (
            <Grid
              key={fieldProps.field}
              item
              xs={12}
              style={{ paddingTop: 0, paddingBottom: 10 }}
            >
              <AttachField {...{ ...fieldProps, label, data, showErrors, updateInfo, errors }} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
