import React from 'react'
import { withStyles, Box, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

import { radioInputStyles } from './radio-input.styles'

export const RadioInput = withStyles(radioInputStyles)(
  ({ classes, readOnly = false, values = [], ...props }) => {
    const getValue = () => {
      let optionSelected
      let valueSelected = '--'
      if (props.value && values) {
        optionSelected = values.find((option) => option.key === props.value)
        valueSelected = optionSelected ? optionSelected.value : '--'
      }
      return valueSelected
    }
    return (
      <Box>
        {props.title ? (
          <Typography variant="subtitle1" gutterBottom>
            {props.title}
          </Typography>
        ) : null}
        {readOnly ? (
          <Typography variant="body1" color="textSecondary" style={{ textTransform: 'capitalize' }}>
            {getValue()}
          </Typography>
        ) : (
          <RadioGroup
            row={props.isColumn ? false : true}
            value={props.value}
            onChange={props.onChange}
            style={{ paddingLeft: 10 }}
            name={props.name}
          >
            {values.map(({ key, value }) => {
              return (
                <FormControlLabel
                  key={key}
                  value={key}
                  name={props.name}
                  control={<Radio size="small" color="primary" />}
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {value}
                    </Typography>
                  }
                />
              )
            })}
          </RadioGroup>
        )}
      </Box>
    )
  }
)
