import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { useSelector } from 'react-redux'
import { Box, Tooltip, Typography, Dialog } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { seguimientoPuestaGasConfig, SELECTRA_TYPE, VALID_STATES } from '../../constants'
import { useApplicationUserPermitsContext } from '../../../../pages/applications/contexts'
import { useAppContext } from '../../contexts'
import { SolveAlertDialog } from '../solve-alert/solve-alert.component'
import { ConceptosRetributivosTableModal } from '../conceptos-retributivos-table-modal/conceptos-retributivos-table-modal.component'

import {
  CampaignsPanel,
  ClientPanel,
  DocumentationPanel,
  HistoryPanel,
  InfoPanel,
  InstallationPanel,
  RelatedAppsPanel,
} from '../panels'
import { CommunicationsBtn } from '../communications'
import { AdvanceButton } from '../advance-button'
import { GasMonitoringList } from '../gas-monitoring'
import { FoldingPanel, ThemeButton, useModalContext } from '../../../../ui'
import { AddObservationDialog } from '../dialogs/add-observation-dialog'
import { AddGcInstallerDialog } from '../dialogs'
import { HeaderButtons } from '../header'
import { GcPanel } from '../panels/gc-panel/gc-panel.component'

export const ApplicationDetail = () => {
  const { formatMessage } = useIntl()
  const { seesDocumentPanel, seesRelatedApps } = useApplicationUserPermitsContext()
  const {
    application,
    postAddInstaller,
    deleteInstaller,
    updateApplicationDocs,
    postGASP,
    putGASP,
    putOnTask,
  } = useAppContext()
  const {
    actions: { open },
  } = useModalContext()

  const [gcDialog, setGCDialog] = useState(false)
  const isOpenAlert = useState(application?.pendiente_peticionario?.length > 0)
  const [currentCampaignCode, setCurrentCampaignCode] = useState()
  const [currentConceptosRetributivos, setCurrentConceptosRetributivos] = useState([])
  const role = useSelector((state) => state.global.role)
  const canSeeTasks = useSelector((state) => state.global.permissions?.ver_tareas)
  const showDocumentationPanel = application.camino_solicitud === '01' && seesDocumentPanel
  const canChangeInstaller = application['empresa_ca'] !== null && application['empresa'] !== null
  const canAddInstaller =
    application['camino_solicitud'] === '01' &&
    application['certificado_IRG3'].id === null &&
    VALID_STATES[application.estado]
  const isGc = application['gran_consumo_id'] ? true : false
  const notValidRoles = role === 'e_commerce' || role === 'delegado' || role === 'gestor'

  const checkValidRoleAndType = () => {
    if (role === 'call_center') {
      if (application['tipo_solicitud'] === 'Call_center') return true
      return false
    } else if (role === 'selectra') {
      if (SELECTRA_TYPE[application['tipo_solicitud']]) return true
      return false
    } else if (role === 'gss_vt') {
      if (application['tipo_solicitud'] === 'GSS_VT') return true
      return false
    } else if (role === 'accom') {
      if (application['tipo_solicitud'] === 'accom') return true
      return false
    }
    return true
  }

  const handleOpenDialog = (isChangeInstaller) => {
    open({
      Component: AddObservationDialog,
      data: {
        isChangeInstaller,
        handleChangeInstaller,
        handleDeleteInstaller,
      },
      type: 'centered',
      closable: true,
    })
  }

  const handleChangeInstaller = (observations) => {
    const payload = { solicitud_id: application.id, observaciones_cambio_instalador: observations }
    postAddInstaller(payload).then((response) => {
      window.location.replace(
        `https://dsc-wordpress.apinedgia-pre.gniinnova.com/configuracion-vivienda/?root=4&id=${response.uuid}`
      )
    })
  }

  const handleDeleteInstaller = (observations) => {
    const payload = { observaciones_cambio_instalador: observations }
    deleteInstaller(payload)
  }

  const handleGcInstaller = () => {
    setGCDialog(true)
  }

  const handleGoTasks = () => {
    const base_url = window.location.origin
    window.open(
      `${base_url}/#!/supervision?_search=${application.solicitud_id}&_pagina=1`
    )
  }

  const handleSolveTaskAlert = (id) => {
    open({
      Component: SolveAlertDialog,
      data: { id, putOnTask, application },
      closable: true,
    })
  }

  const handleToggleConceptoRetributivoTable = (codigo, conceptosRetributivos) => {
    if (codigo === currentCampaignCode && conceptosRetributivos === currentConceptosRetributivos) {
      setCurrentCampaignCode('')
      setCurrentConceptosRetributivos('')
    } else {
      setCurrentCampaignCode(codigo)
      setCurrentConceptosRetributivos(conceptosRetributivos)
    }
  }

  return (
    <>
      <Box mb={2}>
        {isOpenAlert &&
          application.pendiente_peticionario?.map((tarea) => {
            return (
              <Alert
                severity="warning"
                action={
                  <ThemeButton
                    aria-label="close"
                    color="primary"
                    size="small"
                    onClick={() => {
                      handleSolveTaskAlert(tarea.id)
                    }}
                  >
                    Hecho
                  </ThemeButton>
                }
              >
                {`Tienes cambios pendientes. Cuando los hayas realizado pulsa el botón de 'HECHO' para informar al gestor, por favor:  ${tarea.observaciones_sspp}`}
              </Alert>
            )
          })}
      </Box>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography variant="h4" color="textSecondary" paragraph>
          {formatMessage({ id: 'pages.application.detail.title' })}
        </Typography>
        <Box display="flex">
          {application.estado === '03' ||
          application.estado === '90' ||
          application.estado_cups !== 'CONTRATABLE' ? (
            <AdvanceButton />
          ) : null}
          {canSeeTasks ? (
            <Box ml={1}>
              <ThemeButton color="primary" size="small" onClick={handleGoTasks}>
                {formatMessage({ id: 'pages.application.detail.actionButtons.goTask.label' })}
              </ThemeButton>
            </Box>
          ) : null}
          <Box ml={1}>
            <CommunicationsBtn />
          </Box>
        </Box>
      </Box>
      {(role === 'gestor' ||
        role === 'delegado' ||
        role === 'gestor_gc' ||
        role === 'call_center') && (
        <HeaderButtons {...{ application, updateApplicationDocs, postGASP, putGASP }} />
      )}
      <ClientPanel />
      <InfoPanel />
      {showDocumentationPanel ? <DocumentationPanel /> : null}
      <FoldingPanel
        isExpanded={true}
        title={formatMessage({ id: 'pages.application.detail.panels.6.title' })}
      >
        <Box width="100%" pt={2}>
          <GasMonitoringList data={application} config={seguimientoPuestaGasConfig} />
        </Box>
      </FoldingPanel>

      <FoldingPanel
        isExpanded={false}
        title={formatMessage({ id: 'pages.application.detail.installation.title' })}
      >
        {gcDialog ? (
          <Dialog open={gcDialog} maxWidth="xl">
            <AddGcInstallerDialog setGCDialog={setGCDialog} application={application} />
          </Dialog>
        ) : null}
        <Box width="100%" pt={2}>
          <InstallationPanel />

          {canAddInstaller && !notValidRoles ? (
            canChangeInstaller ? (
              <Box display="flex">
                <ThemeButton
                  color="primary"
                  labelM="sm"
                  size="small"
                  onClick={!isGc ? () => handleOpenDialog(true) : handleGcInstaller}
                  disabled={!checkValidRoleAndType()}
                >
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      <Typography variant="body2">
                        {formatMessage({
                          id: 'pages.application.detail.panels.6.cambiar_instalador.tooltip',
                        })}
                      </Typography>
                    }
                  >
                    <Box>
                      {formatMessage({
                        id: 'pages.application.detail.panels.6.cambiar_instalador.title',
                      })}
                    </Box>
                  </Tooltip>
                </ThemeButton>
                {!isGc ? (
                  <ThemeButton
                    color="secondary"
                    labelM="sm"
                    size="small"
                    style={{ marginLeft: '1em' }}
                    onClick={() => handleOpenDialog(false)}
                    disabled={!checkValidRoleAndType()}
                  >
                    <Tooltip
                      placement="top"
                      arrow
                      title={
                        <Typography variant="body2">
                          {formatMessage({
                            id: 'pages.application.detail.panels.6.eliminar_instalador.tooltip',
                          })}
                        </Typography>
                      }
                    >
                      <Box>
                        {formatMessage({
                          id: 'pages.application.detail.panels.6.eliminar_instalador.title',
                        })}
                      </Box>
                    </Tooltip>
                  </ThemeButton>
                ) : null}
              </Box>
            ) : (
              <ThemeButton
                color="primary"
                labelM="sm"
                size="small"
                onClick={!isGc ? () => handleOpenDialog(true) : handleGcInstaller}
                disabled={!checkValidRoleAndType()}
              >
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'pages.application.detail.panels.6.anadir_instalador.tooltip',
                      })}
                    </Typography>
                  }
                >
                  <Box>
                    {formatMessage({
                      id: 'pages.application.detail.panels.6.anadir_instalador.title',
                    })}
                  </Box>
                </Tooltip>
              </ThemeButton>
            )
          ) : null}
        </Box>
      </FoldingPanel>
      {application['gran_consumo_id'] ? <GcPanel /> : null}

      {application.campanas.length || application.campana_op ? (
        <CampaignsPanel
          toggleTable={handleToggleConceptoRetributivoTable}
          currentCampaignCode={currentCampaignCode}
          currentConceptosRetributivos={currentConceptosRetributivos}
          setCurrentCampaignCode={setCurrentCampaignCode}
          setCurrentConceptosRetributivos={setCurrentConceptosRetributivos}
        />
      ) : null}
      <HistoryPanel />
      {seesRelatedApps ? <RelatedAppsPanel /> : null}
    </>
  )
}
