import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const ClonePublicOfferForm = ({ publicOffer, createPublicOffer, history, close }) => {
  const { formatMessage } = useIntl()
  const todayDate = new Date()
  const nuevaFechaInicio = todayDate.setDate(todayDate.getDate() + 7)
  const nuevaFechaFin = todayDate.setDate(todayDate.getDate() + 7)
  const handleFormatTipos = (tipos) => {
    const formattedTipo = tipos.map((tipo) => tipo.key)
    return formattedTipo
  }
  const newPublicOffer = {
    ...publicOffer,
    nombre: publicOffer.nombre + ' bis',
    fechainicio: new Date(nuevaFechaInicio).toISOString(),
    fechapublicacion: new Date(nuevaFechaInicio).toISOString(),
    fechafinvigencia: new Date(nuevaFechaFin).toISOString(),
    tipo_cliente: publicOffer.tipo_cliente ? handleFormatTipos(publicOffer.tipo_cliente) : [],
    tipo_vivienda: publicOffer.tipo_vivienda ? handleFormatTipos(publicOffer.tipo_vivienda) : [],
    tipo_gas: publicOffer.tipo_gas ? handleFormatTipos(publicOffer.tipo_gas) : [],
    activa: false,
  }

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState(newPublicOffer)

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    setLoading(true)
    const { id, ...restFormState } = formState
    const payload = restFormState
    for (const element of restFormState.conceptos_retributivos) {
      element.id = null
    }
    createPublicOffer(payload)
      .then((data) => {
        setLoading(false)
        history.push(`/public-offer/${data.id}`)
        close()
      })
      .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={handleConfirm}>
      <Box mb={4}>
        <TextInput
          {...{
            label: 'Nombre',
            name: 'nombre',
            value: formState['nombre'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box mb={4}>
        <TextInput
          {...{
            label: 'Codigo',
            name: 'codigo',
            value: formState['codigo'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton type="submit" color="primary" labelM="lg" disabled={loading} loading={loading}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </form>
  )
}

export const ClonePublicOfferDialog = (props) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.campaigns.dialogs.clone.title' })}
        </Typography>
      </Box>
      <ClonePublicOfferForm {...props} />
    </Box>
  )
}
