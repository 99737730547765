export const JOBS_TABLE_COLUMNS = [
  { field: "id", width: 20,},
  { field: "name", align: "left", width: 120 },
  { field: "params", align: "left", width: 150 },
  { field: "status", align: "left", width: 100 },
  {
    field: "start_time",
    align: "left",
    type: "date",
    width: 50
  },
  { field: "end_time", align: "left", width: 50},
];


