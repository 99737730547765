import { IP_TABLE_COLUMNS } from '../components/table'
import { IP_EXPORT_COLUMNS } from '../components/table/ip-table.constants'

export const ipTableDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    IP_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
      // tableData: {
      //   ...item.tableData,
      // },
    }
  })
}

export const ipExportDataPreparation = ({ data, combos, intl }) => {
  const filteredData = data.filter((e) => e.estado !== '15')

  const formattedData = filteredData.map((item) => {
    const formattedText = {}
    IP_EXPORT_COLUMNS.forEach((column) => {
      if (column.field === 'tipo_documento') {
        formattedText[column.field] = 'IP'
      }
      if (column.exportType === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)

        formattedText[column.field] = formatedDate
      }
      if (column.field === 'tipo_gas' && item[column.field]) {
        let tipoGas = ''
        if (item[column.field] === 'GAS NATURAL') {
          tipoGas = 'GN'
        } else {
          tipoGas = 'GLP'
        }

        formattedText[column.field] = tipoGas
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
  return formattedData
}
