import React from 'react'
import { Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { AddConceptoRetributivoBtn } from '../buttons'

export const ConceptosRetributivosFilter = () => {
  return (
    <FilterBox>
      {/*<Box display="flex" justifyContent="flex-end">
        <AddConceptoRetributivoBtn />
      </Box>*/}
    </FilterBox>
  )
}
