import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useConceptosRetributivosCalls = () => {
  const { get, getById, putById, post, deleteById } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const displayError = (message) => {
    feedback('error', message)
  }

  const getConceptosRetributivosCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/concepto_retributivo/tabla_conceptos_retributivos', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getConceptoRetributivoCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/concepto_retributivo', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postConceptoRetributivoCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/concepto_retributivo', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              case 400: //Permisson denied by role
                displayError(response.data.message)
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putConceptoRetributivoCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/concepto_retributivo', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteConceptoRetributivoCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        deleteById('/concepto_retributivo', id)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 403: //Permisson denied by role
                return reject(response)
              case 400:
                displayError(
                  formatMessage({ id: 'pages.conceptos.retributivos.actions.delete.error' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getConceptosRetributivosCall,
    getConceptoRetributivoCall,
    postConceptoRetributivoCall,
    putConceptoRetributivoCall,
    deleteConceptoRetributivoCall,
  }
}
