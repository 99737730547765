export const COMPATIBLE_CAMPAIGNS_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 120 },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 100 },
  // Fecha de inicio de la campaña
  { field: 'fechainicio', aling: 'left', type: 'date', width: 100 },
  // Fecha del fin de la campaña
  { field: 'fechafin', aling: 'left', type: 'date', width: 100 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
