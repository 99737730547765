import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Chip, Paper } from "@material-ui/core";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { TableUrlPagination } from '../../../../ui'
import { reportTableStyles } from './report-table.styles'
import { MTableCell } from 'material-table'
import { JOBS_TABLE_COLUMNS } from "./report-table.constants";
import { useRowSelector } from "../../../loader/hooks";
import { TableActions } from "../table-actions";
import { useCampaignsContext } from "../../../campaigns";
import { parse, stringify } from "qs";
import { DEFAULT_QUERY } from "../../../campaigns/components/table/campaigns-table.constants";
import { useJobsContext } from "../../contexts";
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';


const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const ReportTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const {
    data,
    loading,
    search,
    getBatchsReportTable,
    setSelectedTableRows,
    getBatchsReportTableById,
    onClickReportTableRow,
    pagination: { total_reports: totalEntries },
  } = useJobsContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const batchId = urlSearchParams.batch_id
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }

      if (batchId) {
        getBatchsReportTableById(batchId, searchParams)
      } else {
        getBatchsReportTable(searchParams)
      }

    } else {
      history.push(`/jobs/historic?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search])

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      JOBS_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.jobs.report.table.columns.${column.field}.title` }),
          cellStyle: {
            ...reportTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span></span>,
          lookup: null,
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getFormattedCell = (props) => {
    if (props.columnDef.field === 'actions') {
      return <TableActions {...props} />
    }
    if (props.columnDef.field === 'active') {
      return (
        <input type="checkbox" checked={props.value === 'true'} disabled />
      )
    }
    if (props.columnDef.field === 'status') {
      //show a chip with the last report status, green for success, red for error and blue for running. If there is no report, show a grey chip with N/A text inside. Show icon for success and error
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.value === 'OK' ? (
            <Chip label="success" style={{borderColor: '#4caf50', color: '#4caf50', cursor: 'pointer'}} variant="outlined" icon={<DoneIcon />} />
          ) : props.value === 'KO' ? (
            <Chip label="Error" style={{borderColor: '#d32f2f', color: '#d32f2f', cursor: 'pointer'}} color="error" variant="outlined" icon={<ErrorOutline />} />
          ) : props.value === 'running' ? (
            <Chip label="Running" style={{borderColor: '#0288d1', color: '#0288d1', cursor: 'pointer'}} color="primary" variant="outlined" icon={<DirectionsRunIcon />} />
          ) : (
            <Chip label="N/A" color="default" variant="outlined" />
          )}
        </div>
      )
    }
    if (props.columnDef.field === 'params') {
      if(props.value && props.value.length > 0) {
        return (
            props.value.map((arg, index) => {
              return (
                <div key={index}>
                  <b>{arg.key}</b> : {arg.value}
                </div>
              )
            }
        )
      )
      }
    }

    if (props.columnDef.field === 'start_time') {
      return (
        new Date(props.value + "Z").toLocaleString()
      )
    }
    if (props.columnDef.field === 'end_time') {
      //Calculate duration between launch and finish
      if (!props.rowData.end_time) return ('N/A')
      let seconds = (new Date(props.rowData.end_time) - new Date(props.rowData.start_time)) / 1000
      if (seconds < 0) return ('N/A')
      else if (seconds < 3600) return new Date(seconds * 1000).toISOString().slice(14, 19);
      else new Date(seconds * 1000).toISOString().slice(11, 19);
    }
  }


    //Assembling and adjusting data
  const processedData = useMemo(() => {
    return data.map((row) => {
      return {
        ...row,
        active: row.active ? 'true' : 'false',
        job_timing: row.job_timing ? row.job_timing : 'N/A',
        last_report: "success"
      }
    })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: reportTableStyles.header,
      draggable: false,
      tableLayout: 'fixed',
      sorting: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )



  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const mTableCellProps =
          props.columnDef.field === 'end_time' || props.columnDef.field === 'status' || props.columnDef.field === 'start_time' || props.columnDef.field === 'params'  ? { ...props, value: null } : props
        return (
          <MTableCell {...mTableCellProps} style={{ padding: 0 }}>
            {getFormattedCell(props)}
          </MTableCell>
        )
      }
    }),
    []
  )

  const handleRowClick = useCallback(
    (_, row) => {
      onClickReportTableRow(row['id'])
    },
    [onClickReportTableRow]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
