import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { usePublicOfferCalls } from './public-offer-calls.hook'
import { publicOfferReducer } from '../reducers'
import { PUBLIC_OFFER_INITIAL_STATE, PUBLIC_OFFER_ACTIONS_TYPES } from '../reducers'

export const usePublicOfferReducer = () => {
  const [state, dispatch] = useReducer(publicOfferReducer, PUBLIC_OFFER_INITIAL_STATE)
  const history = useHistory()
  const {
    getPublicOffersCall,
    getPublicOfferCall,
    putPublicOfferCall,
    postPublicOfferCall,
    putPublicOfferStatusCall,
    // getPublicOffersGeneralTermsCall,
  } = usePublicOfferCalls()

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: PUBLIC_OFFER_ACTIONS_TYPES.SET_LOADING,
      })
      getPublicOffersCall(config)
        .then(({ data }) => {
          dispatch({
            type: PUBLIC_OFFER_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['campanas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_ofertas_publicas: data['total_campanas'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: PUBLIC_OFFER_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // const getPublicOffersGeneralTerms = useCallback(
  //   (search) => {
  //     dispatch({
  //       type: PUBLIC_OFFER_ACTIONS_TYPES.SET_LOADING,
  //     })
  //     getPublicOffersGeneralTermsCall()
  //       .then(({ data }) => {
  //         dispatch({
  //           type: PUBLIC_OFFER_ACTIONS_TYPES.SET_TABLE_DATA,
  //           payload: {
  //             data: data['ofertas_publicas'],
  //             search: search,
  //             pagination: {
  //               total_paginas: data['total_paginas'],
  //               total_ofertas_publicas: data['total_ofertas_publicas'],
  //             },
  //           },
  //         })
  //       })
  //       .catch(() => {
  //         dispatch({
  //           type: PUBLIC_OFFER_ACTIONS_TYPES.SET_FAILURE,
  //         })
  //       })
  //   },
  //   [dispatch]// eslint-disable-line react-hooks/exhaustive-deps
  // )

  const getPublicOffer = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getPublicOfferCall(id)
          .then(({ data }) => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_PUBLIC_OFFER,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const editPublicOffer = useCallback(
    (payload) => {
      const { id, ...restPayload } = payload
      return new Promise((resolve, reject) => {
        putPublicOfferCall(id, restPayload)
          .then(({ data }) => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_PUBLIC_OFFER,
              payload: data,
            })
            return data
          })
          .then((data) => resolve(data))
          .catch(() => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changePublicOfferStatus = useCallback(
    (id, payload) => {
      return new Promise((resolve, reject) => {
        putPublicOfferStatusCall(id, payload)
          .then(({ data }) => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_PUBLIC_OFFER_STATUS,
              payload: { id, state: data['activa'] },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const createPublicOffer = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        postPublicOfferCall({ ...payload })
          .then(({ data }) => resolve(data))
          .catch(() => {
            dispatch({
              type: PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: PUBLIC_OFFER_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/public-offer/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  return {
    setSelectedTableRows,
    onClickTableRow,
    getTableData,
    getPublicOffer,
    editPublicOffer,
    createPublicOffer,
    changePublicOfferStatus,
    ...state,
  }
}
