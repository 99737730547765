import { Box, Divider, Typography, Tabs, Tab, Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { FoldingPanel } from '../../../../../ui'
import { useAppContext } from '../../../contexts'
import { CampaignGrid } from './campaign.component'
import { useCampaignPanel } from './campaigns-panel.hook'
import { ConceptosRetributivosTable } from '../../conceptos-retributivos-table-modal/conceptos-retributivos-table-modal.component'
import { current } from '@reduxjs/toolkit'

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ConceptoRetributivoButton = ({
  codigo,
  conceptosRetributivos,
  currentCampaignCode,
  currentConceptosRetributivos,
  onClick,
}) => {
  return (
    <Box mt={1}>
      <Button
        style={{ padding: '0' }}
        color="primary"
        onClick={() => onClick(codigo, conceptosRetributivos)}
      >
        {codigo === currentCampaignCode
          ? `Esconder conceptos retributivos`
          : `Ver conceptos retributivos`}
      </Button>
    </Box>
  )
}

export const CampaignsPanel = ({
  toggleTable,
  currentCampaignCode,
  currentConceptosRetributivos,
  setCurrentCampaignCode,
  setCurrentConceptosRetributivos,
}) => {
  const intl = useIntl()
  const { formatMessage: fm } = useIntl()
  const { application } = useAppContext()
  const { campaignPanelFields, opCampaignPanelFields } = useCampaignPanel()
  const [value, setValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
    setCurrentCampaignCode('')
    setCurrentConceptosRetributivos('')
  }

  return (
    <>
      {application.campanas ? (
        <FoldingPanel title={fm({ id: 'pages.application.detail.campaigns.title' })}>
          <Box flex={1} pt={1} pb={1}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChangeTab} aria-label="campaigns tab">
                <Tab label="Campaña 1" {...a11yProps(0)} />
                <Tab label="Campaña 2" {...a11yProps(1)} />
                <Tab label="Campaña 3" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {application.campanas[0] ? (
                <Box>
                  <CampaignGrid fields={campaignPanelFields(0)} />
                  {application.campanas[0].conceptos_retributivos.length ? (
                    <ConceptoRetributivoButton
                      codigo={application.campanas[0].codigo}
                      conceptosRetributivos={application.campanas[0].conceptos_retributivos}
                      currentCampaignCode={currentCampaignCode}
                      currentConceptosRetributivos={currentConceptosRetributivos}
                      onClick={toggleTable}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              ) : null}
              {currentCampaignCode?.length ? (
                <ConceptosRetributivosTable
                  codigo={currentCampaignCode}
                  conceptosRetributivos={currentConceptosRetributivos}
                />
              ) : (
                <></>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {application.campanas[1] ? (
                <Box>
                  <CampaignGrid fields={campaignPanelFields(1)} />
                  {application.campanas[1].conceptos_retributivos.length ? (
                    <ConceptoRetributivoButton
                      codigo={application.campanas[1].codigo}
                      conceptosRetributivos={application.campanas[1].conceptos_retributivos}
                      currentCampaignCode={currentCampaignCode}
                      currentConceptosRetributivos={currentConceptosRetributivos}
                      onClick={toggleTable}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              ) : null}
              {currentCampaignCode?.length ? (
                <ConceptosRetributivosTable
                  codigo={currentCampaignCode}
                  conceptosRetributivos={currentConceptosRetributivos}
                />
              ) : (
                <></>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {application.campanas[2] ? (
                <Box>
                  <CampaignGrid fields={campaignPanelFields(2)} />
                  {application.campanas[2].conceptos_retributivos.length ? (
                    <ConceptoRetributivoButton
                      codigo={application.campanas[2].codigo}
                      conceptosRetributivos={application.campanas[2].conceptos_retributivos}
                      currentCampaignCode={currentCampaignCode}
                      currentConceptosRetributivos={currentConceptosRetributivos}
                      onClick={toggleTable}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              ) : null}
              {currentCampaignCode?.length ? (
                <ConceptosRetributivosTable
                  codigo={currentCampaignCode}
                  conceptosRetributivos={currentConceptosRetributivos}
                />
              ) : (
                <></>
              )}
            </CustomTabPanel>
            {application.campana_op ? (
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
                  {intl.formatMessage({
                    id: `pages.application.detail.campaigns.op_campaign.title`,
                  })}
                </Typography>
                <CampaignGrid fields={opCampaignPanelFields} />
              </Box>
            ) : null}
          </Box>
        </FoldingPanel>
      ) : (
        ''
      )}
    </>
  )
}
