import React, { useEffect, useState, useCallback } from 'react'
import { PostalCodeCheckList } from '../../../../ui/postal-codes-check-list/index'
import { useIntl } from 'react-intl'
import {
  withStyles,
  Typography,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { marketerNewStyles } from './marketer-zone.style'
import { ProvinceList } from '../province-list'
import { useCampaignsCalls } from '../../hooks/campaigns-calls.hook'

export const MarketerZones = withStyles(marketerNewStyles)(
  ({
    classes,
    readOnly,
    provincias,
    handleChangeMunip,
    handleChangeProv,
    cps = [],
    selProvs = [],
    formState,
  }) => {
    provincias = provincias.map((p) => ({ id: p.key, nombre: p.value }))
    const { formatMessage } = useIntl()
    const { getZipMunicipalities } = useCampaignsCalls()
    const [searchQuery, setSearchQuery] = useState(null)
    // const [towns, setTowns] = useState(cps.map((item) => ({ ...item, activa: true })))
    const [towns, setTowns] = useState()
    const [checkedProvinces, setCheckedProvinces] = useState(selProvs)
    const [isLoading, setIsLoading] = useState(false)

    const getTowns = async (provs) => {
      if (provs.length) {
        setIsLoading(true)
        const a = await Promise.all(
          provs.map(async (province) => {
            let codes = await getZipMunicipalities('provincia_id', province.id)
            codes[0].name = province.nombre
            return codes[0]
          })
        )
        const checkedCps = cps?.map((item) => item.cp)
        a.forEach((element) => {
          element.municipios.forEach((municipio) => {
            const formattedCps = municipio.cps.map((cp) => {
              const modifiedCp = {
                cp: cp,
                activa: checkedCps.includes(cp),
                municipio: municipio.municipio,
                provincia: element.provincia,
              }
              return modifiedCp
            })
            municipio.cps = formattedCps
            municipio.activo = formattedCps.map((cp) => {
              const result = cp.activa === true
              return result
            })
          })
        })
        setTowns(a)
        setIsLoading(false)
      } else setTowns([])
    }
    useEffect(() => {
      if (!readOnly) {
        getTowns(checkedProvinces)
      } else {
        getTowns(checkedProvinces)
        // setTowns(cps.map((item) => ({ ...item, activa: true })))
      }
    }, [readOnly, checkedProvinces]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectProvince = (province) => {
      if (readOnly) return
      setCheckedProvinces((provs) => {
        const provsCopy = Array.from(provs)
        const ind = provsCopy.findIndex((item) => item.id === province.id)
        if (ind !== -1) {
          provsCopy.splice(ind, 1)
        } else {
          provsCopy.push(province)
        }
        handleChangeProv(provsCopy)
        return provsCopy
      })
    }

    const handleToggleZip = (zip, checked) => {
      if (readOnly) return
      if (checked) {
        zip.activa = true
      } else {
        zip.activa = false
      }
      handleChangeMunip([zip])
    }

    const handleToggleZipMunicipio = (municipio, checked) => {
      if (readOnly) return
      let updatedCps = []
      municipio.cps.forEach((zip) => {
        if (checked) {
          updatedCps.push()
          zip.activa = true
        } else {
          zip.activa = false
        }
      })
      handleChangeMunip(municipio.cps)
    }

    const handleCheckMunicipality = (municipio) => {
      let isMunicipalityChecked = false
      if (cps.length) {
        isMunicipalityChecked = cps.some(
          (municipioCp) => municipioCp.municipio === municipio.municipio
        )
      }
      return isMunicipalityChecked
    }

    const handleIsZipCodeChecked = (zipCode) => {
      const selectedZipCode = cps.find((selectedCp) => selectedCp.cp === zipCode)
      if (selectedZipCode) {
        return true
      } else {
        return false
      }
    }

    const handleIsProvinceChecked = (element) => {
      const isProvinceSelected = cps.find(
        (selectedCp) => selectedCp.provincia === element.provincia
      )
      if (isProvinceSelected) {
        return true
      } else {
        return false
      }
    }

    const handleToggleZipProvince = (province, checked) => {
      if (readOnly) return
      let zips = []
      province.municipios.forEach((municipality) =>
        municipality.cps.forEach((cp) => {
          if (checked) {
            zips.push({ ...cp, activa: true })
          } else {
            zips.push({ ...cp, activa: false })
          }
        })
      )
      handleChangeMunip(zips)
    }

    const handleWholeZips = (event, zips) => {
      if (readOnly) return
      event.persist()
      const selectedTowns = towns
        .filter((town) => zips.includes(town.cp))
        .map((x) => ({ ...x, activa: event.target.checked }))
      setTowns((prev) => {
        const copy = Array.from(prev ?? [])
        const checkedCps = selectedTowns.map((item) => item.cp)
        const array = copy.map((zip) => {
          if (checkedCps.includes(zip.cp)) {
            return {
              ...zip,
              activa: event.target.checked,
            }
          } else {
            return zip
          }
        })
        return array
      })
      handleChangeMunip(selectedTowns)
    }

    const handleSearchQueryChange = useCallback(
      (event) => {
        const newText = event.target.value
        setSearchQuery(newText)
      },
      [setSearchQuery]
    )
    const zipCheckRender = (element) => {
      const municipalities = element.municipios
      return (
        <Box>
          <Accordion key={element.name} defaultExpanded={readOnly} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <FormControlLabel
                label={element.name || 'Provincia'}
                style={{ paddingLeft: '25px' }}
                control={
                  <Checkbox
                    name={element.name}
                    color="primary"
                    checked={handleIsProvinceChecked(element)}
                    onClick={(e) => handleToggleZipProvince(element, e.target.checked)}
                    disabled={readOnly}
                  />
                }
                onClick={(event) => event.stopPropagation()}
              />
            </AccordionSummary>
            <AccordionDetails style={{ transitionDuration: 0 }}>
              <List dense>
                {municipalities.map((municipio) => {
                  return (
                    <Accordion
                      key={municipio.municipio}
                      defaultExpanded={readOnly}
                      disableGutters={true}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                      >
                        <FormControlLabel
                          label={`${municipio['municipio']}`}
                          style={{ paddingLeft: '25px' }}
                          control={
                            <Checkbox
                              color="primary"
                              name={municipio.municipio}
                              checked={handleCheckMunicipality(municipio)}
                              onClick={(e) => handleToggleZipMunicipio(municipio, e.target.checked)}
                              disabled={readOnly}
                            />
                          }
                          onClick={(event) => event.stopPropagation()}
                        />
                      </AccordionSummary>
                      <AccordionDetails style={{ transitionDuration: 0 }}>
                        <List dense>
                          {municipio['cps'].map((cp) => {
                            return (
                              <ListItem key={cp.cp} style={{ paddingLeft: '50px' }}>
                                <ListItemIcon>
                                  <Checkbox
                                    color="primary"
                                    name={cp.cp}
                                    checked={handleIsZipCodeChecked(cp.cp)}
                                    onClick={(e) => handleToggleZip(cp, e.target.checked)}
                                    disableRipple
                                    disabled={readOnly}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={cp.cp}
                                  style={readOnly ? { color: '#00000061' } : { color: '' }}
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    }

    // const zipBlockRender = useCallback(
    //   ({ children }) => (
    //     <Box className={classes.zipContainer}>
    //       <Accordion key={children.index} defaultExpanded={readOnly}>
    //         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
    //           <FormControlLabel
    //             label={checkedProvinces.length ? `${checkedProvinces[0].nombre}` : 'Provincia'}
    //             style={{ paddingLeft: '25px' }}
    //             control={<Checkbox color="primary" />}
    //             onClick={(event) => event.stopPropagation()}
    //           />
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <List dense>
    //             {children.map((child, index) => (
    //               <ListItem key={index}>
    //                 <ListItemText primary={child} />
    //               </ListItem>
    //             ))}
    //           </List>
    //         </AccordionDetails>
    //       </Accordion>
    //     </Box>
    //   ),
    //   [] // eslint-disable-line react-hooks/exhaustive-deps
    // )

    const funcSearchFilter = (element, filter) => {
      const result = `${element.cp} - ${element.municipio}`
        .toLowerCase()
        .includes(filter.toLowerCase())

      return result
    }

    return (
      <div className={classes.container}>
        <div className={classes.provs}>
          <Typography variant="h6" gutterBottom>
            {formatMessage({ id: 'pages.tariffs.zone.provinces.title' })}
          </Typography>
          <ProvinceList
            className={classes}
            provinces={provincias}
            handleSelectProvince={handleSelectProvince}
            checkedProvinces={checkedProvinces}
          />
        </div>
        <div className={classes.zips}>
          <Typography variant="h6" gutterBottom>
            {formatMessage({ id: 'pages.tariffs.zone.zips.title' })}
          </Typography>
          {isLoading ? (
            <Box
              className={classes.loader}
              display="flex"
              height={100}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={40} />
            </Box>
          ) : (
            <PostalCodeCheckList
              className={classes.colorPanel}
              keyField="cp"
              nameField="cp"
              elements={towns}
              selected={towns && towns.filter((zip) => zip.activa).map((el) => el.cp)}
              funcRenderElement={zipCheckRender}
              // funcRenderContainer={zipBlockRender}
              searchBox={true}
              searchValue={searchQuery}
              searchPlaceholder={formatMessage({ id: 'pages.tariffs.zone.zips.filter' })}
              funcSearchOnChange={handleSearchQueryChange}
              funcSearchFilter={funcSearchFilter}
              wholeSelector={false}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.zips.all' })}
              funcWholeOnChange={handleWholeZips}
            />
          )}
        </div>
      </div>
    )
  }
)
