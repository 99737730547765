import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { TablePagination } from '@material-ui/core'
import MaterialTable from 'material-table'

const localization = (formatMessage) => ({
  pagination: {
    labelDisplayedRows: formatMessage({ id: 'pagination.labelDisplayedRows' }),
    labelRowsSelect: formatMessage({ id: 'pagination.labelRowsSelect' }),
    firstAriaLabel: formatMessage({ id: 'pagination.firstAriaLabel' }),
    firstTooltip: formatMessage({ id: 'pagination.firstTooltip' }),
    previousAriaLabel: formatMessage({ id: 'pagination.previousAriaLabel' }),
    previousTooltip: formatMessage({ id: 'pagination.previousTooltip' }),
    nextAriaLabel: formatMessage({ id: 'pagination.nextAriaLabel' }),
    nextTooltip: formatMessage({ id: 'pagination.nextTooltip' }),
    lastAriaLabel: formatMessage({ id: 'pagination.lastAriaLabel' }),
    lastTooltip: formatMessage({ id: 'pagination.lastTooltip' }),
  },
})

/*README
Material Table has an importan bug:
even if options, paging and ordering are properly updated,
they are only displayed in re-rendering when 2 conditions happen in order:
- The ref.current.dataManager state is updated internally to the new values (does not trigger render)
- The table is re-rendered by a state change, such as onLoading going to false.
In order to optimize the number of renderings, we shall combine url readings with search parameters
The former will anticipate the requested data, whilst the latter will correspond to the data provided
- Trigger dataManager updates only according to url pageSize, orderings
- The attributes of the dataTable itself only update when search parameters do
//UPDATE: The effect seems to be giving problems only in paging, so, for the time being,
all this complexity will be restricted to that case.
*/

export const TableUrlPagination = ({
  columns,
  components,
  options,
  search = {}, //Precedence over url to avoid unnecessary re-renders
  totalEntries = 0,
  ...props
}) => {
  const history = useHistory()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const currentSearch = new URLSearchParams(location.search)
  const tableRef = useRef() //Will point to the table

  //Managing paging and set paging block and options

  const urlPageSizeParsed = parseInt(currentSearch.get('_num_x_pagina'))
  const urlPageSize = isNaN(urlPageSizeParsed)
    ? !props.data || !props.data.length
      ? 5
      : props.data.length
    : urlPageSizeParsed
  const pageSizeParsed = parseInt(search['_num_x_pagina'])
  const pageSize = isNaN(pageSizeParsed) ? urlPageSize : pageSizeParsed
  const currentPageParsed = parseInt(search['_pagina'] || currentSearch.get('_pagina'))
  const currentPage = isNaN(currentPageParsed) ? 1 : currentPageParsed

  const handleChangePage = useCallback(
    (e, page) => {
      currentSearch.set('_pagina', parseInt(page) + 1)
      history.replace({
        search: '?' + currentSearch.toString(),
      })
    },
    [currentSearch, history]
  )

  const handleChangeRowsPerPage = useCallback(
    (pageSize) => {
      currentSearch.set('_pagina', 1)
      currentSearch.set('_num_x_pagina', pageSize)
      history.replace({
        search: '?' + currentSearch.toString(),
      })
    },
    [currentSearch, history]
  )

  const updatedOptions = useMemo(
    () => ({
      ...options,
      pageSize: pageSize,
    }),
    [options, pageSize]
  )

  const tableComponents = useMemo(
    () => ({
      ...components,
      Pagination: (paginationProps) => (
        <TablePagination
          {...paginationProps}
          rowsPerPageOptions={[5, 10, 20]}
          count={totalEntries}
          page={currentPage - 1}
          onChangePage={handleChangePage}
        />
      ),
    }),
    [handleChangePage, totalEntries, currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    tableRef.current.dataManager.changePageSize(pageSize)
  }, [tableRef.current, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  //Managing ordering and updating columns:

  const order = search['_order'] || currentSearch.get('_order')
  const order_field = search['_order_by'] || currentSearch.get('_order_by')
  const customSort = () => {} //To avoid ordering

  const [formattedColumns, order_by] = useMemo(() => {
    let order_by = -1
    const formattedColumns = columns.map((column, i) => {
      // To sort
      if (column.field === order_field) {
        order_by = i
        column.defaultSort = order
      } else {
        column.defaultSort = null
      }

      //To avoid ordering
      column.customSort = column.customSort || customSort

      return column
    })
    return [formattedColumns, order_by]
  }, [columns, order_field, order])

  const handleChangeOrder = useCallback(
    (orderedColumnId, orderDirection) => {
      const order = orderDirection
      const order_by = parseInt(orderedColumnId)
      const order_field = columns[order_by] ? columns[order_by].field : null
      currentSearch.set('_pagina', 1)
      order ? currentSearch.set('_order', order) : currentSearch.delete('_order')
      order_field ? currentSearch.set('_order_by', order_field) : currentSearch.delete('_order_by')
      history.replace({
        search: '?' + currentSearch.toString(),
      })
    },
    [columns, currentSearch, history]
  )
  useEffect(() => {
    tableRef.current.dataManager.changeOrder(order_by, order)
  }, [tableRef.current, order_by, order]) // eslint-disable-line react-hooks/exhaustive-deps

  //Function to build table parts

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={formattedColumns}
      options={updatedOptions}
      localization={useCallback(localization(formatMessage), [])}
      components={tableComponents}
      onPageChange={() => {}}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onOrderChange={handleChangeOrder}
      {...props}
    />
  )
}
