import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { PageDisplayer } from '../../../../ui'
import { ConceptosRetributivosForm } from '../../../../features/conceptos-retributivos'

export const ConceptosRetributivosCreatePage = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/conceptos-retributivos' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.conceptos.retributivos.detail.goBack' })}
    >
      <Box mb={5}>
        <Typography variant="h4" color="textPrimary">
          {formatMessage({ id: 'pages.conceptos.retributivos.create.title' })}
        </Typography>
        <ConceptosRetributivosForm />
      </Box>
    </PageDisplayer>
  )
}
