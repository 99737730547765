export const CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS = [
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 120 },
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 120 },
  { field: 'importe', align: 'center', width: 100 },
  { field: 'momento_pago', align: 'center', width: 100 },
  { field: 'usuarios_finca_desde', align: 'center', width: 100 },
  { field: 'puesta_servicio_desde', align: 'center', width: 100 },
  { field: 'num_captaciones_desde', align: 'center', width: 100 },
  { field: 'saturacion_desde', align: 'center', width: 100 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
