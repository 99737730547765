import React, { useMemo } from 'react'
import { withStyles, TextField, FormControl, FormHelperText, Tooltip } from '@material-ui/core'
import { Icon, Typography, MenuItem, Box } from '@material-ui/core'

import { textInputStyles } from './text-input.styles'

const ROWS_MAX = 5
const DEFAULT_MAX_LENGTH = 250

export const TextInput = React.memo(
  withStyles(textInputStyles)(
    ({ classes, type = 'text', readOnly = false, rowsMax = ROWS_MAX, ...props }) => {
      const InputProps = useMemo(() => {
        return { className: classes.input, ...props.InputProps }
      }, [classes.input, props.InputProps])
      const tooltip = useMemo(
        () => (
          <span style={{ fontSize: 16, marginLeft: 4 }}>
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          </span>
        ),
        [props.tooltip]
      )

      const inputPropsDefault = {
        pattern: props.required ? '.*[^ ].*' : null,
        maxLength: DEFAULT_MAX_LENGTH,
      }

      const typographyVariant = props.fontSize === 'small' ? '2' : '1'
      return (
        <FormControl className={classes.root}>
          <Box>
            {props.title && (
              <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
                {props.title}
              </Typography>
            )}
            {props.tooltip ? tooltip : null}
          </Box>
          {readOnly ? (
            <Typography
              variant={`body${typographyVariant}`}
              color="textSecondary"
              style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}
            >
              {props.value ? props.value + (props.units ? ' ' + props.units : '') : '--'}
            </Typography>
          ) : (
            <>
              <TextField
                maxRows={rowsMax}
                {...props}
                type={type}
                variant="outlined"
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={props.onChange}
                InputProps={InputProps}
                inputProps={{ ...inputPropsDefault, ...props.inputProps }}
              >
                {props.values
                  ? props.values.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
              {(props.errorMessage || props.helpMessage)  && (<FormHelperText id="my-helper-text" error={props.error}>
                {props.error ? props.errorMessage : props.helpMessage}
              </FormHelperText>)}
            </>
          )}
        </FormControl>
      )
    }
  )
)
