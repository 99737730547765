export const preparePublicOfferDto = (originalForm) => {
  const form = { ...originalForm }
  if (!form.descuento) {
    form.descuento = 0
  }
  if (!form.ingreso) {
    form.ingreso = 0
  }
  if (typeof form.ingreso === 'string') {
    form.ingreso = parseFloat(form.ingreso.replace(',', '.'))
  }
  if (typeof form.descuento === 'string') {
    form.descuento = parseFloat(form.descuento.replace(',', '.'))
  }
  if (form.financiacion === '01') {
    form.financiacion = true
    if (typeof form.limite_financiacion === 'string') {
      form.limite_financiacion = parseFloat(form.limite_financiacion.replace(',', '.'))
    }
    const result = Math.round(parseFloat(form.meses_financiacion))
    if (result < 0) form.meses_financiacion = 0
    else form.meses_financiacion = result
  } else {
    form.financiacion = false
    form.limite_financiacion = null
    form.meses_financiacion = null
  }
  if (
    form.mercados?.some(
      (mercado) =>
        mercado.nombre === 'Pyme' ||
        mercado.nombre === 'PYME' ||
        mercado.nombre === 'Gran Consumo' ||
        mercado.nombre === 'GC' ||
        mercado.nombre === 'Caldera Centralizada' ||
        mercado.nombre === 'CC'
    )
  ) {
    //Campaign without elementos/productos
    form.elementos = []
  } else {
    //Campaign without tariff
    form.tarifas = []
  }

  form.comunidades.forEach((comunidad) => {
    if (comunidad.d_condiciones_economicas_nuevo) {
      comunidad.d_condiciones_economicas = {
        documento: comunidad.d_condiciones_economicas_nuevo.data,
        nombre: comunidad.d_condiciones_economicas_nuevo.name,
        extension: comunidad.d_condiciones_economicas_nuevo.type,
      }
      delete comunidad.d_condiciones_economicas_nuevo
    }
  })

  for (let key in form) {
    if (form[key] === null) delete form[key]
  }

  if (form.tipo_cliente) {
    form.tipo_cliente = form.tipo_cliente.map((cliente) => cliente.key)
  }

  if (form.tipo_gas) {
    form.tipo_gas = form.tipo_gas.map((gas) => gas.key)
  }

  if (form.tipo_vivienda) {
    form.tipo_vivienda = form.tipo_vivienda.map((vivienda) => vivienda.key)
  }

  return form
}
