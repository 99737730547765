export const CAMPAIGNS_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 120 },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 70 },
  // Comunidad Autónoma
  { field: 'ccaa', align: 'left', width: 130 },
  // Mercados
  { field: 'mercados', align: 'left', width: 80 },
  // Productos
  { field: 'productos', align: 'left', width: 120 },
  // Subvención
  // { field: 'descuento', align: 'right', width: 100 },
  // Ingreso a cuenta
  // { field: 'ingreso', align: 'right', width: 100 },
  // Conceptos Retributivos
  { field: 'conceptos_retributivos', align: 'left', width: 175 },
  // Importe Puesta en Servicio
  // { field: 'importe_puesta_servicio', align: 'left', width: 125 },
  // Momento Pago
  // Importe Captación
  { field: 'importe', align: 'left', width: 80 },
  //Financiación
  {
    field: 'financiacion',
    align: 'left',
    width: 80,
    render: (rowData) => (rowData.financiacion ? `${rowData.financiacion}` : 'No'),
  },
  // Prioridad de la campaña
  // { field: 'prioridad', align: 'left', width: 150 },
  // Activa
  { field: 'state', disableClick: true, align: 'center', width: 120 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
