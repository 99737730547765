import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Box, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { SelectInput, CheckListInput, TextInput, FileUploaderInput } from '../../../../ui'
import { GRID_ITEM_CONF, FM_ROOT } from './loc-select-input.constants'
import { MarketerZones } from '../zones'

const LocSelectInputRow = ({
  item,
  index,
  ccaaValues,
  provValues,
  guildValues,
  changeItem,
  rmItem,
  inputProps,
}) => {
  const { formatMessage: fm } = useIntl()

  const MAX_LENGTH = 1500

  const handleChangeCCAA = (e) => {
    const newItem = {
      ...item,
      id: e.target.value,
      nombre: ccaaValues.find((ca) => ca.key === e.target.value).value,
      provincias: [],
      gremios: guildValues
        .filter((g) => g.ca === e.target.value)
        .map((g) => ({ id: g.key, nombre: g.value })),
    }
    changeItem(newItem, index)
  }
  const handleDeleteCCAA = () => rmItem(index)

  const handleChangeProv = (provs) => {
    const provIds = provs.map((item) => parseInt(item.id))
    const newItem = {
      ...item,
      provincias: provs,
      cps: item.cps?.filter((zip) => provIds.includes(zip.provincia)),
    }
    changeItem(newItem, index)
  }

  const handleChangeGuild = (e) => {
    const newItem = {
      ...item,
      gremios: e.target.value,
    }
    changeItem(newItem, index)
  }

  const handleChangeDocCondicionesEconomicas = (e) => {
    if (e.target.value) {
      e.target.value.data = e.target.value.data.split(',').pop()
    }
    const newItem = {
      ...item,
      d_condiciones_economicas_nuevo: e.target.value,
    }
    changeItem(newItem, index)
  }

  const handleChangeMunip = (currentZips) => {
    let newItem = { ...item }
    const cpCopy = Array.from(item.cps ?? [])
    for (const currentZip of currentZips) {
      const { activa, ...zip } = currentZip
      if (activa) {
        const cpIndex = cpCopy.findIndex(
          (element) => element.cp === zip.cp && element.municipio === zip.municipio
        )
        if (cpIndex === -1) {
          cpCopy.push(zip)
        }
      } else {
        const cpIndex = cpCopy.findIndex(
          (element) => element.cp === zip.cp && element.municipio === zip.municipio
        )
        cpCopy.splice(cpIndex, 1)
      }
    }
    newItem.cps = cpCopy
    changeItem(newItem, index)
  }

  const handleChangeCond = (e) => {
    const newItem = {
      ...item,
      pielegal: e.target.value,
    }
    changeItem(newItem, index)
  }

  const handleChangeCodZeus = (e) => {
    const newItem = {
      ...item,
      cod_zeus: e.target.value,
    }
    changeItem(newItem, index)
  }

  const handleChangeDesc = (e) => {
    const newItem = {
      ...item,
      descripcion: e.target.value,
    }
    changeItem(newItem, index)
  }

  return (
    <Box style={index > 0 ? { marginTop: '5em' } : { marginTop: '' }}>
      <Grid {...GRID_ITEM_CONF}>
        <SelectInput
          {...{
            ...inputProps,
            readOnly: index > -1 ? true : inputProps.readOnly,
            name: 'ccaa',
            title: fm({
              id: FM_ROOT + `comunidades.${index > -1 ? '1' : '2'}.title`,
            }),
            value: item.id,
            values: ccaaValues,
            onChange: handleChangeCCAA,
          }}
        />
      </Grid>
      <Grid
        key={index}
        spacing={4}
        style={{ marginTop: 0, marginBottom: 0, backgroundColor: '#F2F6F8' }}
      >
        {item.provincias && (
          <Box padding={'1.5em'}>
            <MarketerZones
              {...{
                readOnly: inputProps.readOnly,
                provincias: provValues.filter((v) => v.ca === item.id),
                handleChangeMunip,
                handleChangeProv,
                cps: item.cps,
                selProvs: item.provincias,
              }}
            />
          </Box>
        )}
        {index > -1 ? (
          <Box display={'flex'} flexDirection={'column'} padding={'1.5em'}>
            {guildValues?.length ? (
              <Box>
                <CheckListInput
                  {...{
                    ...inputProps,
                    value: item.gremios,
                    values: guildValues.filter((guild) => guild.ca.some((ca) => ca === item.id)),
                    title: fm({ id: FM_ROOT + 'gremios.title' }),
                    name: 'gremios',
                    keyField: 'id',
                    valueField: 'nombre',
                    onChange: handleChangeGuild,
                  }}
                />
              </Box>
            ) : null}
            <Box mt={1}>
              <TextInput
                {...{
                  ...inputProps,
                  multiline: true,
                  rows: 10,
                  rowsMax: 24,
                  name: 'pielegal',
                  title: 'Condiciones',
                  value: item.pielegal,
                  onChange: handleChangeCond,
                  inputProps: { maxLength: MAX_LENGTH },
                }}
              />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mt={1}>
              <Box>
                <TextInput
                  style={{ minWidth: '20em' }}
                  {...{
                    ...inputProps,
                    name: 'cod_zeus',
                    title: 'Código de Oferta Pública de Zeus',
                    value: item.cod_zeus,
                    onChange: handleChangeCodZeus,
                  }}
                />
              </Box>
              <Box {...GRID_ITEM_CONF}>
                <TextInput
                  style={{ minWidth: '20em' }}
                  {...{
                    ...inputProps,
                    name: 'descripcion',
                    title: 'Descripción de Oferta Pública de Zeus ',
                    value: item.descripcion,
                    onChange: handleChangeDesc,
                  }}
                />
              </Box>
            </Box>
            <Box mt={1}>
              <FileUploaderInput
                {...{
                  ...inputProps,
                  value: item['d_condiciones_economicas_nuevo'],
                  name: `d_condiciones_economicas_nuevo_${index}`,
                  title: 'Documento Condiciones Económicas',
                  onChange: handleChangeDocCondicionesEconomicas,
                  currentDoc: item['d_condiciones_economicas'],
                  fileProps: {
                    maxSize: 10000000, // 10Mb
                    accept: ['application/pdf'],
                  },
                }}
              />
            </Box>
          </Box>
        ) : null}
        {!inputProps.readOnly && index > -1 ? (
          <Box textAlign={'right'} padding={2}>
            <Button
              onClick={handleDeleteCCAA}
              size="large"
              variant="outlined"
              color="primary"
              style={{ backgroundColor: 'white', padding: '1em 3em' }}
            >
              {fm({ id: 'global.erase' })}
            </Button>
          </Box>
        ) : null}
      </Grid>
    </Box>
  )
}

export const LocSelectInput = ({
  name,
  readOnly = false,
  value = [],
  ccaaValues = [],
  provValues = [],
  guildValues = [],
  formState,
  onChange,
  ...props
}) => {
  const inputProps1 = { readOnly, fontSize: props.fontSize, required: props.required }
  const inputProps2 = { readOnly, fontSize: props.fontSize }
  const ccaaValuesFiltered = ccaaValues.filter((ca) => !value.some((v) => v.id === ca.key))

  const handleChange = (newItem, index) => {
    let newValue = [...value]
    if (index !== undefined) {
      newValue[index] = newItem
    } else {
      newValue.push(newItem)
    }
    onChange({ target: { name, value: newValue } })
  }

  const handleRemove = (index) => {
    value.splice(index, 1)
    onChange({ target: { name, value } })
  }
  return (
    <>
      {value.map((item, index) => (
        <Box mb={8}>
          <LocSelectInputRow
            key={index}
            {...{
              item,
              index,
              ccaaValues,
              provValues,
              guildValues,
              changeItem: handleChange,
              rmItem: handleRemove,
              inputProps: inputProps1,
            }}
          />
        </Box>
      ))}
      {!readOnly && ccaaValuesFiltered.length ? (
        <LocSelectInputRow
          {...{
            item: {},
            ccaaValues: ccaaValuesFiltered,
            provValues,
            guildValues,
            changeItem: handleChange,
            inputProps: inputProps2,
          }}
        />
      ) : null}
    </>
  )
}
