import React, { useMemo } from 'react'
import { withStyles, Tooltip, Icon, Typography, Box } from '@material-ui/core'
import { formSectionTitleStyles } from './form-section-title.styles'

export const FormSectionTitle = withStyles(formSectionTitleStyles)(
  ({
    classes,
    readOnly = false,
    value = '',
    decimalScale = undefined,
    fixedDecimalScale = false,
    maxValue = 0,
    units = null,
    ...props
  }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const tooltip = useMemo(() => {
      return props.tooltip ? (
        <span style={{ fontSize: 16, marginLeft: 4 }}>
          <Tooltip
            placement="top"
            title={
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {props.tooltip}
              </Typography>
            }
          >
            <Icon fontSize="inherit">help_outline</Icon>
          </Tooltip>
        </span>
      ) : null
    }, [props.tooltip])

    return (
      <>
        {props.title ? (
          <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
            {props.title + (tooltip ? tooltip : '')}
          </Typography>
        ) : null}
      </>
    )
  }
)
