import { CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS } from '../components/table'

export const conceptosRetributivosDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
    }
  })
}
