import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import {
  ConceptosRetributivosTable,
  ConceptosRetributivosFilter,
} from '../../../../features/conceptos-retributivos'
import { conceptosRetributivosDashboardStyles } from './conceptos-retributivos-dashboard.styles'

export const ConceptosRetributivosDashboardPage = withStyles(conceptosRetributivosDashboardStyles)(
  ({ classes }) => {
    const { formatMessage } = useIntl()

    return (
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.conceptos.retributivos.dashboard.title' })}
          </Typography>
        </Box>
        <Box mb={2} display="flex">
          <ConceptosRetributivosFilter />
        </Box>
        <Box>
          <ConceptosRetributivosTable />
        </Box>
      </Container>
    )
  }
)
