import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, FormControl, Select, MenuItem, ListItemText } from '@material-ui/core'
import { Checkbox, CircularProgress } from '@material-ui/core'

import { filterInputStyles } from './filter-input.styles'

export const FilterInput = withStyles(filterInputStyles)(
  ({ classes, values = [], multiple = false, ...props }) => {
    if (!values || values.length === 0) {
      return null
    }
    const { formatMessage } = useIntl()
    const value = multiple ? (props.value ? props.value.split(',') : []) : props.value || ''

    const DEFAULT_FILTER_VALUE = formatMessage({ id: 'global.all' })

    const [selectedValues, setSelectedValues] = useState([])

    useEffect(() => {
      setSelectedValues(value)
    }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
      if (multiple) {
        setSelectedValues(e.target.value)
      } else props.onChange(e)
    }

    const getSelectOptions = () => {
      const options = values.map((item, i) => (
        <MenuItem key={i} value={item.key}>
          {multiple ? (
            <>
              <Checkbox checked={selectedValues.indexOf(item.key) > -1} />
              <ListItemText primary={item.value} />
            </>
          ) : (
            item.value
          )}
        </MenuItem>
      ))
      if (!multiple)
        options.unshift(
          <MenuItem key="all" value="">
            {DEFAULT_FILTER_VALUE}
          </MenuItem>
        )
      return options
    }

    const getRenderValue = (selectedValue) => {
      return (
        <span>
          {props.label}:
          <span className={classes.inlineValue}>
            {values.length ? (
              (!Array.isArray(selectedValue) && selectedValue !== '') ||
              (Array.isArray(selectedValue) && selectedValue.length) ? (
                multiple ? (
                  selectedValues
                    .map((valueSelected) => values.find(({ key }) => key === valueSelected))
                    .filter((el) => el)
                    .map((el) => el.value)
                    .join(', ')
                ) : (
                  values.find(({ key }) => key === selectedValue).value
                )
              ) : (
                DEFAULT_FILTER_VALUE
              )
            ) : (
              <CircularProgress size={14} />
            )}
          </span>
        </span>
      )
    }

    const onCloseProps = multiple
      ? { onClose: () => props.onChange({ target: { name: props.name, value: selectedValues } }) }
      : {}

    return (
      <FormControl
        variant="outlined"
        className={classes.root}
        required={props.required}
        disabled={props.disabled}
      >
        <Select
          autoWidth
          className={classes.input}
          name={props.name}
          value={multiple ? selectedValues : value}
          multiple={multiple}
          onChange={handleChange}
          {...onCloseProps}
          displayEmpty
          renderValue={getRenderValue}
          MenuProps={{ variant: 'menu' }}
        >
          {getSelectOptions()}
        </Select>
      </FormControl>
    )
  }
)
