import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Switch } from '@material-ui/core'
import { ThemeButton } from '../../../../ui'
import { INPUTS_CONFIG, CONCEPTOS_RETRIBUTIVOS_FORM } from './conceptos-retributivos-form.constants'
import { GridForm } from './grid-form.component'
import { useConceptosRetributivosContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'

export const ConceptosRetributivosForm = () => {
  const history = useHistory()
  const intl = useIntl()
  const combos = useCombos(['gremios'])
  const {
    createConceptoRetributivo,
    editConceptoRetributivo,
    conceptoRetributivo,
    resetConceptoRetributivoState,
  } = useConceptosRetributivosContext()
  const isCreateMode = useLocation().pathname.includes('/new')
  const [formState, setFormState] = useState({
    usuarios_finca: false,
    puesta_servicio: false,
    num_captaciones: false,
    saturacion: false,
  })
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  useEffect(() => {
    if (isCreateMode) {
      resetConceptoRetributivoState()
    } else {
      const handleSetEditForm = () => {
        setFormState(conceptoRetributivo)
      }
      if (conceptoRetributivo) {
        handleSetEditForm()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateMode, conceptoRetributivo])

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  const handleChangeSwitch = (e) => {
    setFormState({ ...formState, [e.target.name]: !formState[e.target.name] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = !formState.nombre
    if (!errors) {
      setLoading(true)
      createConceptoRetributivo(formState)
        .then((data) => {
          setLoading(false)
          history.push(`/conceptos-retributivos`)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setErrorMssg('Se requiere el nombre del concepto retributivo')
    }
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    const errors = !formState.nombre
    if (!errors) {
      setLoading(true)
      editConceptoRetributivo(formState)
        .then((data) => {
          setLoading(false)
          history.push(`/conceptos-retributivos/${formState.id}`)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setErrorMssg('Algo ha ido mal al editar')
    }
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: false,
  })
  return (
    <form onSubmit={isCreateMode ? handleSubmit : handleUpdate}>
      {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
      <Box paddingX={'3em'}>
        {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
        <Box ml={1.45}>
          <GridForm {...{ grid: CONCEPTOS_RETRIBUTIVOS_FORM, inputs: INPUTS }} />
        </Box>
        <Box>
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Switch
              color="primary"
              onChange={handleChangeSwitch}
              name="usuarios_finca"
              checked={formState.usuarios_finca}
            />
            <Typography>
              {intl.formatMessage({
                id: `pages.conceptos.retributivos.detail.fields.numero_usuarios_finca.title`,
              })}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Switch
              color="primary"
              onChange={handleChangeSwitch}
              name="puesta_servicio"
              checked={formState.puesta_servicio}
            />
            <Typography>
              {intl.formatMessage({
                id: `pages.conceptos.retributivos.detail.fields.rango_puesta_servicio.title`,
              })}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Switch
              color="primary"
              onChange={handleChangeSwitch}
              name="num_captaciones"
              checked={formState.num_captaciones}
            />
            <Typography>
              {intl.formatMessage({
                id: `pages.conceptos.retributivos.detail.fields.numero_captaciones.title`,
              })}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Switch
              color="primary"
              onChange={handleChangeSwitch}
              name="saturacion"
              checked={formState.saturacion}
            />
            <Typography>
              {intl.formatMessage({
                id: `pages.conceptos.retributivos.detail.fields.saturacion.title`,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box textAlign={'right'}>
        {isCreateMode ? (
          <ThemeButton type="submit" color="primary" loading={loading}>
            {intl.formatMessage({
              id: `pages.conceptos.retributivos.create.button.save.label`,
            })}
          </ThemeButton>
        ) : (
          <ThemeButton type="submit" color="primary" loading={loading}>
            {intl.formatMessage({
              id: 'pages.conceptos.retributivos.edit.button.save.label',
            })}
          </ThemeButton>
        )}
      </Box>
    </form>
  )
}
