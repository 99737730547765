import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography, Chip, IconButton, withStyles, Tooltip } from '@material-ui/core'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

import { useCombos } from '../../../combos'
import { companyPanelStyles } from './company-panel.styles'

export const PORequests = withStyles(companyPanelStyles)(
  ({ classes, company, data = [], canNavigate = false }) => {
    const history = useHistory()
    const { formatMessage: fm, formatDate: fd } = useIntl()
    const combos = useCombos(['estados_peticion_op'])
    const OP_STATES = { REJECTED: { key: '00', value: 'Rechazada' } }

    const handleNavigateToPORequest = useCallback(
      (opRequestId) => history.push(`/po-requests/${opRequestId}`),
      [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleNavigateToPORequestList = useCallback(() => {
      const props = {
        pathname: '/po-requests',
        search: company.nombre ? `?_search=${company.nombre}` : '',
      }
      return history.push(props)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getStateValue = (stateKey) => {
      const poRequestCombo = combos.estados_peticion_op?.data
      const stateValue = poRequestCombo.find((item) => item.key === stateKey)?.value
      return stateValue || stateKey
    }

    return useMemo(
      () => (
        <>
          <Box display="flex" alignItems="center" mb={3}>
            <Typography variant="h6" color="primary">
              {fm({ id: 'pages.eeii.detail.poRequests.title' })}
            </Typography>
            {canNavigate && data.length ? (
              <IconButton onClick={handleNavigateToPORequestList} style={{ marginLeft: 10 }}>
                <ExitToAppOutlinedIcon />
              </IconButton>
            ) : null}
          </Box>
          {data.length ? (
            <Box>
              {data
                .sort((a, b) => new Date(b.dg_ts_insert) - new Date(a.dg_ts_insert))
                .map((item) => {
                  return (
                    <Box
                      key={item.id}
                      className={classes.poRow}
                      onClick={canNavigate ? () => handleNavigateToPORequest(item.id) : null}
                    >
                      <Box>
                        <Typography variant="subtitle1">{item.nombre}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {fm(
                            { id: 'pages.eeii.detail.poRequests.fields.date.title' },
                            { date: fd(item.dg_ts_insert) }
                          )}
                        </Typography>
                      </Box>

                      {item.estado === OP_STATES.REJECTED.key ? (
                        <Tooltip arrow title={item.observaciones_rechazo}>
                          <Box>
                            <Chip clickable size="small" label={getStateValue(item.estado)} />
                          </Box>
                        </Tooltip>
                      ) : (
                        <Box>
                          <Chip clickable size="small" label={getStateValue(item.estado)} />
                        </Box>
                      )}
                    </Box>
                  )
                })}
            </Box>
          ) : (
            <Typography variant="body1" color="textSecondary" paragraph>
              {fm({ id: 'pages.eeii.detail.poRequests.noData' })}
            </Typography>
          )}
        </>
      ),
      [data] // eslint-disable-line react-hooks/exhaustive-deps
    )
  }
)
