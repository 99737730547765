import { useState, useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { useAppContext } from '../../../contexts'
import { useInfoPanelFeedback } from './info-panel-feedback'
import { useAppCalls } from '../../../hooks'
import { BUDGET_NOT_SENT_YET_STATES } from './info-panel.constants'

const TEXT_AREA_LENGTH = 250

const getAppStatesCombo = (role) => {
  let appStatesComboId = 'estados_solicitud'
  switch (role) {
    case 'call_center':
    case 'selectra':
    case 'gss_vt':
    case 'accom':
      appStatesComboId += '_call_center'
      break
    case 'delegado':
    case 'gestor':
    case 'gestor_gc':
    case 'e_commerce':
    case 'gestor_sir':
      appStatesComboId += '_gestores'
      break
    default:
      return undefined
  }
  return appStatesComboId
}

export const useInfoPanel = () => {
  const { formatMessage: fm } = useIntl()
  const combos = useSelector((state) => state.combos)
  const role = useSelector((state) => state.global.role)
  const { application, getApplication, changeLoading, loading } = useAppContext()
  const infoPanelFeedback = useInfoPanelFeedback()
  const { generateBudgetCall, putAppCall, putBudgetCall, putSCRCall } = useAppCalls()
  const { getAvailableStatesCall, changeToAvailableStateCall } = useAppCalls()

  const [formState, setFormState] = useState(application)
  const [hasFormChanged, setFormHasChanged] = useState(false)

  const i18nInfoPanel = useCallback(
    (field) => fm({ id: 'pages.application.detail.info.' + field + '.label' }),
    [fm]
  )

  const handleChangeField = useCallback(
    (e) => {
      const { name, value } = e.target
      setFormState({ ...formState, [name]: value })
      if (!hasFormChanged) {
        setFormHasChanged(true)
      }
    },
    [formState, hasFormChanged]
  )

  const saveForm = () => {
    return new Promise(async (resolve, reject) => {
      const budgetDocNeedsUpdate =
        BUDGET_NOT_SENT_YET_STATES[application.estado] &&
        !!application.presupuesto_exterior?.d_presupuesto
      let editedAppFields = {}
      let editedBudgetFields = {}
      let editedSCRFields = {}
      for (let key in formState) {
        if (formState[key] !== application[key]) {
          if (key === 'cod_expediente_zeus') {
            editedSCRFields[key] = formState[key]
          } else if (key.startsWith('ppto_ext_')) {
            editedBudgetFields[key.replace('ppto_ext_', '')] = formState[key]
          } else {
            editedAppFields[key] = formState[key]
          }
        }
      }
      changeLoading(true)
      infoPanelFeedback.init()
      try {
        if (Object.keys(editedSCRFields).length) {
          await putSCRCall(application.scr_id, editedSCRFields)
        }
        if (Object.keys(editedAppFields).length) {
          await putAppCall(application.id, editedAppFields)
        }
        if (Object.keys(editedBudgetFields).length) {
          if (editedBudgetFields['distancia_real']) {
            editedBudgetFields['distancia_real'] = parseFloat(
              editedBudgetFields['distancia_real'].replace(',', '.')
            )
          }
          if (editedBudgetFields['consumo_anual']) {
            editedBudgetFields['consumo_anual_real'] = editedBudgetFields['consumo_anual']
          }
          if (editedBudgetFields['descuento_acometida']) {
            editedBudgetFields['descuento_acometida'] = parseFloat(
              editedBudgetFields['descuento_acometida'].replace(',', '.')
            )
          }
          await putBudgetCall(application.presupuesto_exterior.id, editedBudgetFields)
        }
        if (budgetDocNeedsUpdate) {
          infoPanelFeedback.update('Actualizando documentos...')
          generateBudgetCall({ solicitud_id: application.id })
        }
        await getApplication(application.id)
        if (budgetDocNeedsUpdate) {
          infoPanelFeedback.success('Datos guardados. Documento actualizado.')
        } else {
          infoPanelFeedback.success('Datos guardados.')
        }
        setFormHasChanged(false)
        changeLoading(false)
        resolve()
      } catch (err) {
        infoPanelFeedback.error()
        setFormState(application)
        setFormHasChanged(false)
        changeLoading(false)
        reject(err)
      }
    })
  }

  const getAvailableStatesToChange = useCallback(() => {
    return new Promise((resolve, reject) => {
      getAvailableStatesCall(application.id)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }, [application.id, getAvailableStatesCall])

  const changeToAvailableState = (state) => {
    return new Promise((resolve, reject) => {
      changeLoading(true)
      changeToAvailableStateCall(application.id, state)
        .then(({ data }) => {
          getApplication(application.id).then(() => {
            changeLoading(false)
            resolve(data)
          })
        })
        .catch(() => {
          changeLoading(false)
          reject()
        })
    })
  }

  const infoPanelFields = useMemo(() => {
    const DEFAULT_PROPS = (field) => ({
      name: field,
      value: formState[field],
      title: i18nInfoPanel(field),
      fontSize: 'small',
      onChange: handleChangeField,
      required: true,
      readOnly: true,
      disabled: loading,
    })
    return {
      solicitud_id: {
        ...DEFAULT_PROPS('solicitud_id'),
      },
      cidi: {
        ...DEFAULT_PROPS('cidi'),
      },
      tipo_vivienda: {
        ...DEFAULT_PROPS('tipo_vivienda'),
        fullWidth: true,
        values: combos.tipo_vivienda_gestor?.data || [],
      },
      numero_tarifa_aplicada: {
        ...DEFAULT_PROPS('numero_tarifa_aplicada'),
      },
      ppto_ext_precio_instalacion_tuberia: {
        ...DEFAULT_PROPS('ppto_ext_precio_instalacion_tuberia'),
        units: '€',
      },
      ppto_ext_descuento_acometida: {
        ...DEFAULT_PROPS('ppto_ext_descuento_acometida'),
        decimalScale: 2,
        units: '€',
      },
      ppto_ext_fecha_solicitud: {
        ...DEFAULT_PROPS('ppto_ext_fecha_solicitud'),
        allowKeyboard: false,
      },
      ppto_ext_distancia_acometida: {
        ...DEFAULT_PROPS('ppto_ext_distancia_acometida'),
        units: 'm',
      },
      ppto_ext_distancia_real: {
        ...DEFAULT_PROPS('ppto_ext_distancia_real'),
        decimalScale: 2,
        units: 'm',
      },
      rango_presion: {
        ...DEFAULT_PROPS('rango_presion'),
      },
      rango_presion_potenciales: {
        ...DEFAULT_PROPS('rango_presion_potenciales'),
      },
      ppto_ext_plazo_dias_obras: {
        ...DEFAULT_PROPS('ppto_ext_plazo_dias_obras'),
        decimalScale: 0,
      },
      cod_expediente_zeus: {
        ...DEFAULT_PROPS('cod_expediente_zeus'),
        required: false,
      },
      tipo_gas: {
        ...DEFAULT_PROPS('tipo_gas'),
        fullWidth: true,
        values: combos.tipo_gas?.data || [],
      },
      distribuidora: {
        ...DEFAULT_PROPS('sociedad'),
        fullWidth: true,
        values: combos.distribuidora?.data || [],
      },
      estado: {
        ...DEFAULT_PROPS('estado'),
        fullWidth: true,
        values: combos[getAppStatesCombo(role)]?.data || [],
      },
      tipo_solicitud: {
        ...DEFAULT_PROPS('tipo_solicitud'),
        fullWidth: true,
      },
      fecha_solicitud: {
        ...DEFAULT_PROPS('fecha_solicitud'),
        allowKeyboard: false,
      },
      fecha_puesta_servicio: {
        ...DEFAULT_PROPS('fecha_puesta_servicio'),
        allowKeyboard: false,
      },
      codigo_financiacion: {
        ...DEFAULT_PROPS('codigo_financiacion'),
      },
      ppto_ext_consumo_anual: {
        ...DEFAULT_PROPS('ppto_ext_consumo_anual'),
        fullWidth: true,
      },
      ppto_ext_consumo_anual_real: {
        ...DEFAULT_PROPS('ppto_ext_consumo_anual_real'),
        fullWidth: true,
      },
      num_encargo_goda: {
        ...DEFAULT_PROPS('num_encargo_goda'),
      },

      num_gor_icaro: {
        ...DEFAULT_PROPS('num_gor_icaro'),
      },
      iban_sociedad: {
        ...DEFAULT_PROPS('iban_sociedad'),
      },
      tipo_pago: {
        ...DEFAULT_PROPS('tipo_pago'),
      },
      ppto_ext_fecha_caducidad: {
        ...DEFAULT_PROPS('ppto_ext_fecha_caducidad'),
        allowKeyboard: false,
      },
      observaciones_cliente: {
        ...DEFAULT_PROPS('observaciones_cliente'),
        multiline: true,
        rowsMax: 3,
        inputProps: { maxLength: TEXT_AREA_LENGTH },
        fullWidth: true,
      },
      observaciones_gestor: {
        ...DEFAULT_PROPS('observaciones_gestor'),
        multiline: true,
        rowsMax: 3,
        inputProps: { maxLength: TEXT_AREA_LENGTH },
        fullWidth: true,
      },
      observaciones: {
        ...DEFAULT_PROPS('observaciones'),
        multiline: true,
        rowsMax: 3,
        inputProps: { maxLength: TEXT_AREA_LENGTH },
        fullWidth: true,
      },
      observaciones_cambio_estado: {
        ...DEFAULT_PROPS('observaciones_cambio_estado'),
        multiline: true,
        rowsMax: 3,
        inputProps: { maxLength: TEXT_AREA_LENGTH },
        fullWidth: true,
      },
      motivo_anulacion_cliente: {
        ...DEFAULT_PROPS('motivo_anulacion_cliente'),
      },
      motivo_anulacion_gestor: {
        ...DEFAULT_PROPS('motivo_anulacion_gestor'),
        values: combos.motivos_anulacion_workaround?.data || [],
      },
    }
  }, [formState, combos, role, handleChangeField, i18nInfoPanel, loading])

  useEffect(() => {
    setFormState(application)
  }, [application])

  useEffect(() => {
    const listener = (e) => {
      if (hasFormChanged) {
        e.preventDefault()
        const mssg = 'Si abandonas la página, perderás los cambios sin Guardar.'
        e.returnValue = mssg
        return mssg
      }
    }
    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [hasFormChanged])

  return {
    infoPanelFields,
    saveForm,
    hasFormChanged,
    loading,
    getAvailableStatesToChange,
    changeToAvailableState,
  }
}
