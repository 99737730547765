export const JOBS_TABLE_COLUMNS = [
  { field: "active", width: 40, sorting: true },
  { field: "name", align: "left", width: 120 },
  { field: "scheduled", align: "left", width: 140 },
  {
    field: "repository",
    align: "left",
    sorting: false,
    width: 140
  },
  { field: "description", align: "left", width: 300},
  { field: "last_status", align: "center", width: 130 },
  { field: "actions", align: "right", width: 220 }
];


