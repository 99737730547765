import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { useConceptosRetributivosContext } from '../../../../features/conceptos-retributivos'
import { PageDisplayer } from '../../../../ui'
import { ConceptosRetributivosForm } from '../../../../features/conceptos-retributivos'

export const ConceptosRetributivosDetailPage = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { detailLoading, detailError, getConceptoRetributivo } = useConceptosRetributivosContext()
  const { conceptoRetributivoId } = useParams()

  useEffect(() => {
    getConceptoRetributivo(conceptoRetributivoId)
  }, [conceptoRetributivoId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/conceptos-retributivos' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      loading={detailLoading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.conceptos.retributivos.detail.goBack' })}
    >
      {detailError ? (
        formatMessage(
          { id: 'pages.conceptos.retributivos.detail.error' },
          { conceptoRetributivoId }
        )
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h4" color="textPrimary">
              {formatMessage({ id: 'pages.conceptos.retributivos.edit.title' })}
            </Typography>
          </Box>
          <ConceptosRetributivosForm />
        </>
      )}
    </PageDisplayer>
  )
}
