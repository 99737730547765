import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'
import { CAMPAIGNS_TABLE_COLUMNS, DEFAULT_QUERY } from './campaigns-table.constants'
import { campaignsTableStyles } from './campaigns-table.styles'
import { useCampaignsContext } from '../../contexts'
import { campaignsDataPreparation } from '../../logics'
import { CampaignStateSwitch } from '../table-actions'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const CampaignsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useSelector((state) => state.combos)
  const {
    data,
    pagination: { total_campanas: totalEntries },
    loading,
    search,
    tableSelection,
    onClickTableRow,
    setSelectedTableRows,
    getTableData,
  } = useCampaignsContext()
  const [tableSelectionReseted, setTableSelectionReseted] = useState(false)

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    } else {
      history.push(`/campaigns?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tableSelectionReseted) {
      setSelectedTableRows([])
      setTableSelectionReseted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSelectionReseted])

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      CAMPAIGNS_TABLE_COLUMNS.map((column) => {
        if (column.field === 'state') {
          return {
            ...column,
            title: formatMessage({ id: `pages.campaigns.table.columns.${column.field}.title` }),
          }
        }
        return {
          ...column,
          title: formatMessage({ id: `pages.campaigns.table.columns.${column.field}.title` }),
          cellStyle: {
            ...campaignsTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return campaignsDataPreparation({ data, combos, intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        if (row.tableData) {
          row.tableData.checked = true
        }
      }
      return row
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      showSelectAllCheckbox: false,
      headerStyle: campaignsTableStyles.header,
      tableLayout: 'fixed',
      fixedColumns: {
        right: 1,
      },
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'state' ? <CampaignStateSwitch {...props} /> : null}
        </MTableCell>
      ),
    }),
    []
  )
  const handleRowClick = useCallback(
    (_, row) => {
      onClickTableRow(row['id'])
    },
    [onClickTableRow]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
