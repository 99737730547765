export const CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS = [
  // Nombre concepto
  { field: 'nombre', align: 'left', width: 105 },
  // Código concepto
  { field: 'codigo', align: 'left', width: 69 },
  //Usuarios finca
  { field: 'usuarios_finca', align: 'center', width: 78 },
  //Rango Puesta en servicio
  { field: 'puesta_servicio', align: 'center', width: 78 },
  //Número de captaciones
  { field: 'num_captaciones', align: 'center', width: 78 },
  //Rango saturación
  { field: 'saturacion', align: 'center', width: 78 },
  //Rango saturación
  { field: 'momento_pago', align: 'center', width: 78 },
  //Editar concepto
  // { field: 'edit', align: 'right', width: 1 },
  //Eliminar concepto
  // { field: 'delete', align: 'right', width: 1 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
