import React from 'react'
import { Grid, Button } from '@material-ui/core'

import { TextInput, DateInput, NumberInput } from '../../../../../ui'
import { GRID_ITEM } from './campaigns-panel.constants'

export const CampaignGrid = ({ fields }) => {
  return (
    <Grid container spacing={3}>
      {fields.codigo?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.codigo }} />
        </Grid>
      )}
      {fields.nombre?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.nombre }} />
        </Grid>
      )}
      {fields.fechapublicacion?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fechapublicacion }} />
        </Grid>
      )}
      {fields.fechafinvigencia?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fechafinvigencia }} />
        </Grid>
      )}
      {fields.tiempograbacion?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput {...{ ...fields.tiempograbacion }} />
        </Grid>
      )}
      {fields.fechainicio?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fechainicio }} />
        </Grid>
      )}
      {fields.fechafin?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fechafin }} />
        </Grid>
      )}
      {fields.descuento?.value > 0 && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.descuento }} />
        </Grid>
      )}
      {fields.ingreso?.value > 0 && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.ingreso }} />
        </Grid>
      )}
      {fields.limite_financiacion?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.limite_financiacion }} />
        </Grid>
      )}
      {fields.meses_financiacion?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.meses_financiacion }} />
        </Grid>
      )}
      {fields.oferta_especial?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.oferta_especial }} />
        </Grid>
      )}
      {fields.origen?.value && (
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.origen }} />
        </Grid>
      )}
    </Grid>
  )
}
