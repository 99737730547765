import React from 'react'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button, Box, Grid, Typography } from '@material-ui/core'

import { NumberInput, SelectInput } from '../../../../ui'
import { GRID_ITEM_CONF, FM_ROOT } from './loc-select-input.constants'

const ConceptoRetributivoSelector = ({
  selectorCompatibleConceptosRetributivos,
  handleSelectConceptoRetributivo,
  showConcept,
  readOnly,
}) => {
  return (
    <>
      {readOnly ? (
        ''
      ) : showConcept ? (
        ''
      ) : (
        <Select
          placeholder="Selecciona un concepto"
          options={selectorCompatibleConceptosRetributivos}
          onChange={handleSelectConceptoRetributivo}
          theme={(theme) => ({
            ...theme,
            borderRadius: '4px',
            colors: {
              ...theme.colors,
              primary: '#004a6b',
            },
          })}
          styles={{
            input: (baseStyles) => ({
              ...baseStyles,
              height: '38px',
            }),
          }}
        />
      )}
    </>
  )
}

const ConceptoRetributivoSelectInputRow = ({
  item,
  index,
  changeItem,
  rmItem,
  inputProps,
  conceptosRetributivos,
}) => {
  const { formatMessage: fm } = useIntl()
  const location = useLocation()
  const handleOnChange = (e) => {
    const newItem = {
      ...item,
      [e.target.name]: e.target.value,
    }
    changeItem(newItem, index)
  }
  function hasRange(hasRango, desde, hasta) {
    return hasRango || desde != undefined || hasta != undefined
  }

  let hasUsuariosFinca = hasRange(
    item.hasRangoUsuariosFinca,
    item.usuarios_finca_desde,
    item.usuarios_finca_hasta
  )
  let hasPuestaServicio = hasRange(
    item.hasRangoPuestaServicio,
    item.puesta_servicio_desde,
    item.puesta_servicio_hasta
  )
  let hasCaptaciones = hasRange(
    item.hasRangoNumCaptaciones,
    item.num_captaciones_desde,
    item.num_captaciones_hasta
  )
  let hasSaturacion = hasRange(
    item.hasRangoSaturacion,
    item.saturacion_desde,
    item.saturacion_hasta
  )

  function rangeDesdeHasta(title, desde, hasta) {
    return (<Box>
      <Box mb={1} mt={4}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} md={5}>
          <Typography>Desde</Typography>
          <NumberInput
            name={'usuarios_finca_desde'}
            onChange={handleOnChange}
            value={desde}
            readOnly={inputProps.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Typography>Hasta</Typography>
          <NumberInput
            name={'usuarios_finca_hasta'}
            onChange={handleOnChange}
            value={hasta}
            readOnly={inputProps.readOnly}
          />
        </Grid>
      </Grid>
    </Box>)
  }

  const selectorCompatibleConceptosRetributivos = conceptosRetributivos.map((cr) => ({
    ...cr,
    label: cr.nombre,
  }))

  const handleSelectConceptoRetributivo = (e) => {
    const newItem = {
      ...item,
      id: null,
      concepto_retributivo_id: e.key,
      nombre: selectorCompatibleConceptosRetributivos.find((cr) => cr.key === e.key).value,
      hasRangoNumCaptaciones:
        selectorCompatibleConceptosRetributivos.find((cr) => cr.key === e.key).num_captaciones ||
        false,
      hasRangoPuestaServicio:
        selectorCompatibleConceptosRetributivos.find((cr) => cr.key === e.key).puesta_servicio ||
        false,
      hasRangoUsuariosFinca:
        selectorCompatibleConceptosRetributivos.find((cr) => cr.key === e.key).usuarios_finca ||
        false,
      hasRangoSaturacion:
        selectorCompatibleConceptosRetributivos.find((cr) => cr.key === e.key).saturacion || false,
      momento_pago: e.momento_pago,
    }
    changeItem(newItem, index)
  }
  const handleDeleteConceptoRetributivo = () => rmItem(index)
  return (
    <Box style={index > -1 ? { marginTop: '1em', marginBottom: '1em' } : { marginTop: '' }}>
      <Grid {...GRID_ITEM_CONF}>
        <Box maxWidth={'22rem'} mb={2}>
          <ConceptoRetributivoSelector
            selectorCompatibleConceptosRetributivos={selectorCompatibleConceptosRetributivos}
            handleSelectConceptoRetributivo={handleSelectConceptoRetributivo}
            showConcept={index > -1 ? true : inputProps.showConcept}
            readOnly={inputProps.readOnly}
          />
        </Box>
      </Grid>
      <Grid
        key={index}
        spacing={4}
        style={{ marginTop: 0, marginBottom: 0, backgroundColor: '#F2F6F8' }}
      >
        {!inputProps.showConcept && index > -1 ? (
          <Box padding={2}>
            <Box mb={3}>
              <Typography variant="subtitle1">{item.nombre}</Typography>
            </Box>
            <Box display={'flex'} flexDirection="column">
              <Box mb={2}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography>Importe</Typography>
                    <NumberInput
                      name={'importe'}
                      onChange={handleOnChange}
                      value={item.importe}
                      units={'€'}
                      readOnly={inputProps.readOnly}
                    />
                  </Grid>
                </Grid>
              </Box>
              {item.hasRangoNumCaptaciones ||
              item.hasRangoPuestaServicio ||
              item.hasRangoUsuariosFinca ||
              item.periodo ? (
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography>Periodo de pago</Typography>
                    <NumberInput
                      name={'periodo'}
                      onChange={handleOnChange}
                      value={item.periodo}
                      readOnly={inputProps.readOnly}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              { hasUsuariosFinca ?  rangeDesdeHasta(
                'Rango de usuarios en finca',
                item.usuarios_finca_desde,
                item.usuarios_finca_hasta
              ) : (
                <></>
              )}
              { hasPuestaServicio ? rangeDesdeHasta(
                'Rango puesta en Servicio',
                item.puesta_servicio_desde,
                item.puesta_servicio_hasta
              ) : (
                <></>
              )}
              { hasCaptaciones ? rangeDesdeHasta(
                'Rango de Captaciones',
                item.num_captaciones_desde,
                item.num_captaciones_hasta
              ) : (
                <></>
              )}
              { hasSaturacion ? rangeDesdeHasta(
                'Rango de Saturación',
                item.saturacion_desde,
                item.saturacion_hasta
              ) : (
                <></>
              )}
            </Box>
            {!inputProps.readOnly && (
              <Box textAlign={'right'} mt={6}>
                <Button
                  onClick={handleDeleteConceptoRetributivo}
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ backgroundColor: 'white', padding: '1em 3em' }}
                >
                  {fm({ id: 'global.erase' })}
                </Button>
              </Box>
            )}
          </Box>
        ) : null}
      </Grid>
    </Box>
  )
}

export const ConceptosRetributivosSelectInput = ({
  name,
  readOnly = false,
  showConcept = false,
  value = [],
  formState,
  conceptosRetributivos = [],
  onChange,
  ...props
}) => {
  const { formatMessage: fm } = useIntl()

  const inputProps1 = { showConcept, fontSize: props.fontSize, required: props.required, readOnly }
  const inputProps2 = { showConcept, fontSize: props.fontSize, readOnly }
  const handleChange = (newItem, index) => {
    let newValue = [...value]
    if (index !== undefined) {
      newValue[index] = newItem
    } else {
      newValue.push(newItem)
    }
    onChange({ target: { name, value: newValue } })
  }

  const handleRemove = (index) => {
    value.splice(index, 1)
    onChange({ target: { name, value } })
  }

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1">
          {fm({
            id: FM_ROOT + `concepto_retributivo.title`,
          })}
        </Typography>
      </Box>
      {value.map((item, index) => (
        <ConceptoRetributivoSelectInputRow
          key={index}
          {...{
            item,
            index,
            conceptosRetributivos,
            changeItem: handleChange,
            rmItem: handleRemove,
            inputProps: inputProps1,
            formState,
          }}
        />
      ))}
      {readOnly ? (
        ''
      ) : !showConcept && conceptosRetributivos.length ? (
        <>
          {value[0] ? (
            <Box mb={1}>
              <Typography variant="subtitle1">
                {fm({
                  id: FM_ROOT + `concepto_retributivo.another`,
                })}
              </Typography>
            </Box>
          ) : null}
          <ConceptoRetributivoSelectInputRow
            {...{
              item: {},
              changeItem: handleChange,
              inputProps: inputProps2,
              conceptosRetributivos,
              formState,
            }}
          />
        </>
      ) : null}
    </>
  )
}
