import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const usePublicOfferCalls = () => {
  const { get, getById, putById, post } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const displayError = (message) => {
    feedback('error', message)
  }

  const getPublicOffersCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/campana/tabla_ofertas_publicas', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPublicOffersGeneralTermsCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/campana/condiciones_generales', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPublicOfferCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/campana', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postPublicOfferCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/campana', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              case 400: //Permisson denied by role
                displayError(response.data.message)
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postPublicOfferGeneralTerms = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/campana/condiciones_generales', payload)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              case 400: //Permisson denied by role
                displayError(response.data.message)
                return reject(response.status)
              default:
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putPublicOfferCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/campana', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              case 400:
                displayError(response.data.message)
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putPublicOfferStatusCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/campana/cambiar_estado', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getZipMunicipalities = useCallback((type, code) => {
    return new Promise((resolve, reject) => {
      const config = { params: { [type]: code } }
      get('/campana/codigos_postales', config).then(({ data }) => resolve(data))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    getPublicOffersCall,
    getPublicOfferCall,
    postPublicOfferCall,
    putPublicOfferCall,
    putPublicOfferStatusCall,
    getPublicOffersGeneralTermsCall,
    postPublicOfferGeneralTerms,
    getZipMunicipalities,
  }
}
