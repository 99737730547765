import React from 'react'

import { ConceptosRetributivosContextContext } from '../../features/conceptos-retributivos'
import { ConceptosRetributivosRouter } from './routers'

export const ConceptosRetributivosPage = (props) => {
  return (
    <ConceptosRetributivosContextContext>
      <ConceptosRetributivosRouter {...props} />
    </ConceptosRetributivosContextContext>
  )
}
