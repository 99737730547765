import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  ConceptosRetributivosDashboardPage,
  ConceptosRetributivosDetailPage,
  ConceptosRetributivosCreatePage,
} from '../components'

export const ConceptosRetributivosRouter = () => {
  return (
    <Switch>
      <Route exact path="/conceptos-retributivos" component={ConceptosRetributivosDashboardPage} />
      <Route exact path="/conceptos-retributivos/new" component={ConceptosRetributivosCreatePage} />
      <Route
        exact
        path="/conceptos-retributivos/:conceptoRetributivoId"
        component={ConceptosRetributivosDetailPage}
      />
      <Redirect from="*" to="/conceptos-retributivos" />
    </Switch>
  )
}
