import React from 'react'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { useConceptosRetributivosContext } from '../../contexts'
import { Tooltip } from '@material-ui/core'

export const ConceptosRetributivosDelete = ({ classes, props }) => {
  const { deleteConceptoRetributivo } = useConceptosRetributivosContext()
  const conceptoRetributivoId = props.rowData.id
  const handleDelete = (e) => {
    deleteConceptoRetributivo(conceptoRetributivoId)
  }

  if (props.rowData.is_editable)
    return <DeleteIcon onClick={handleDelete} style={{ cursor: 'pointer', fontSize: '1.8em' }} />
  return (
    <Tooltip title="No se puede eliminar el concepto debido a que está asignado a campañas">
      <DeleteIcon style={{ fontSize: '1.8em', color: 'lightgray' }} />
    </Tooltip>
  )
}
