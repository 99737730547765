import { TextInput, NumberInput } from '../../../../ui'

const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}

export const INPUTS_CONFIG = ({ data, combos, intl, onChange, readOnly }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.conceptos.retributivos.detail.fields.' + extension + '.title' })
  const DEFAULT_PROPS = { onChange, readOnly, fontSize: 'small', required: true }
  return {
    NOMBRE_CONCEPTO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['nombre'],
      title: txt('nombre_concepto'),
      name: 'nombre',
    },
    CODIGO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['codigo'],
      title: txt('codigo'),
      name: 'codigo',
      required: true,
      readOnly: true,
    },
  }
}

export const CONCEPTOS_RETRIBUTIVOS_FORM = [
  {
    fields: [
      { ref: 'NOMBRE_CONCEPTO', conf: GRID_ITEM_LARGE },
      { ref: 'CODIGO', conf: GRID_ITEM_LARGE },
    ],
  },
]
