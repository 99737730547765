import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import { jobsDashboardStyles } from './jobs-dashboard.styles'
import { JobsTable } from "../../../../features/jobs";
import PropTypes from "prop-types";
import { ReportTable } from "../../../../features/jobs/components/report-table/report-table.component";
import { useHistory } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const JobsDashboardPage = withStyles(jobsDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const [value, setValue] = React.useState(0);
  let history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      history.push('/jobs');
    }
    if (newValue === 1) {
      history.push('/jobs/historic');
    }
  };
  return (
    <Container className={classes.container} maxWidth={false}>
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.jobs.dashboard.title' })}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Select jobs or historic" centered>
            <Tab label={formatMessage({ id: 'pages.jobs.dashboard.tab.jobs.title' })} {...a11yProps(0)} />
            <Tab label={formatMessage({ id: 'pages.jobs.dashboard.tab.reports.title' })} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <JobsTable />
        </CustomTabPanel>
      </Box>
    </Container>
  )
})
