import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import {
  CampaignsCreateStepForm,
  CampaignsCreateForm,
  useCampaignsContext,
} from '../../../../features/campaigns'
import { useCombos } from '../../../../features/combos/hooks'
import { CAMPAIGNS_COMBOS_IDS } from '../../constants'
import { PageDisplayer } from '../../../../ui'

export const CampaignsCreatePage = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { setSelectedTableRows } = useCampaignsContext()
  useCombos(CAMPAIGNS_COMBOS_IDS)

  const handleGoBack = () => {
    setSelectedTableRows([])

    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/campaigns' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.campaigns.detail.goBack' })}
    >
      <Box mb={5}>
        <Typography variant="h4" color="textPrimary">
          {formatMessage({ id: 'pages.campaigns.create.title' })}
        </Typography>
      </Box>
      <CampaignsCreateStepForm />
      {/* <CampaignsCreateForm /> */}
    </PageDisplayer>
  )
}
