import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { useCombos } from '../../../features/combos'
import { GuildForm, GuildActionButtons, useGuildCalls } from '../../../features/guild-detail'
import { PageDisplayer } from '../../../ui'

export const GuildDetail = (props) => {
  const { formatMessage: fm } = useIntl()
  const history = useHistory()
  const { getGuild } = useGuildCalls()
  const { id } = useParams()
  useCombos(['cod_zona_zeus'])

  const [guildData, setGuildData] = useState(null)

  useEffect(() => {
    getGuild(id).then(({ data }) => {
      setGuildData(data)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    history.goBack()
  }

  if (!guildData) {
    return (
      <Box display="flex" height={100} justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  return (
    <PageDisplayer
      maxWidth="md"
      handleGoBack={handleGoBack}
      textGoBack={fm({ id: 'global.previous' })}
    >
      <GuildActionButtons {...{ model: guildData }} />
      <GuildForm {...{ model: guildData }} />
    </PageDisplayer>
  )
}
