import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useAppCalls = () => {
  const { getById, put, putById, post, get } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getAppCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 403: //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putAppCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putAppDocsCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes/update_docs', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putBudgetCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/presupuesto', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptBudgetCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        putById('/presupuesto/aceptar', id, { aceptacion: true })
          .then((response) => resolve(response))
          .catch(({ response }) => reject(response.status))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSCRCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/scr', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const generateBudgetCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/presupuesto/generar_presupuesto', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendBudgetCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/send_mail/presupuesto/', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validatePaymentCall = useCallback(
    (id, state, config) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambiar_estado/${id}/${state}`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelApplicationCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        put('/solicitudes/anular_solicitud', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAppEventCall = useCallback((id, payload) => {
    return new Promise((resolve, reject) => {
      post(`/bitacora/add_comment/${id}`, payload)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getAppEventsCall = useCallback((id) => {
    return new Promise((resolve, reject) => {
      getById('/bitacora', id)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const putClientCall = (id, payload) =>
    new Promise((resolve, reject) => {
      putById('/usuario', id, payload)
        .then((response) => {
          feedback(
            'success',
            formatMessage({ id: 'pages.application.detail.dialogs.editClient.feedback.5' })
          )
          return resolve(response)
        })
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              if (response.data?.code === 3508) {
                feedback(
                  'error',
                  formatMessage({ id: 'pages.application.detail.dialogs.editClient.feedback.4' })
                )
                return reject(response)
              }
              defaultError()
              break
            case 403: //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const requestChangeCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/${id}/avance_manual`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getAvailableStatesCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes/cambios_manuales_estados', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changeToAvailableStateCall = useCallback(
    (id, state, config) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambios_manuales_estados/${id}?estado=${state}`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAddInstallerCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/solicitudes/asociar_instalador', payload)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.detail.panels.6.anadir_instalador.success' })
            )
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data?.code === 4009) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              case 500:
                if (response.data?.code === 8001) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putTask = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`tareas/${id}`, payload)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.detail.panels.6.resolver_incidencia.success' })
            )
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data?.code === 4009) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              case 500:
                if (response.data?.code === 8001) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteInstallerCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/asociar_instalador/${id}`, payload)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.detail.panels.6.eliminar_instalador.success' })
            )
            resolve(response.data)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const uploadPaymentFileCall = useCallback(
    (application, fileData) =>
      new Promise((resolve, reject) => {
        const payload = {
          comprobante_id: fileData.id,
        }
        putById('/presupuesto', application['presupuesto_exterior']['id'], payload)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const sendFormCall = useCallback(
    (payload, id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/${id}`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changeInstallerCall = useCallback(
    (payload, id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/asociar_instalador_gc/${id}`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPaymentInfoCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        post(`/pagos/${id}/realizar_pago`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const postGASPCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/gas_p', payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putGASPCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`/gas_p/${id}`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putHoldAppCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/validar/${id}/mantener_cartera`)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putCandeDevolutionWithoutTaskAppCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/validar/${id}/anular_sin_devolver`)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getInstallerValuesCall = useCallback(
    (name) =>
      new Promise((resolve, reject) => {
        get(`/empresa_ca/obtener_empresa_filiales`, { params: { nombre: name } })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  return {
    getAppCall,
    putAppCall,
    putAppDocsCall,
    putBudgetCall,
    acceptBudgetCall,
    putSCRCall,
    generateBudgetCall,
    uploadPaymentFileCall,
    sendBudgetCall,
    validatePaymentCall,
    cancelApplicationCall,
    getAppEventsCall,
    putHoldAppCall,
    postAppEventCall,
    putClientCall,
    requestChangeCall,
    getAvailableStatesCall,
    changeToAvailableStateCall,
    putCandeDevolutionWithoutTaskAppCall,
    postAddInstallerCall,
    deleteInstallerCall,
    sendFormCall,
    getPaymentInfoCall,
    getInstallerValuesCall,
    changeInstallerCall,
    postGASPCall,
    putGASPCall,
    putTask,
  }
}
