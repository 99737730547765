import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MultiStepForm, Step } from 'react-multi-form-dark-blue'
import { ThemeButton } from '../../../../ui'
import {
  INPUTS_CONFIG,
  STEP_1_FORM_CONFIG,
  STEP_2_FORM_CONFIG,
  STEP_3_FORM_CONFIG,
  STEP_4_FORM_CONFIG,
  STEP_5_FORM_CONFIG,
} from './campaign-form.constants'
import { GridForm } from './grid-form.component'
import { useCampaignsContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'
import { prepareCampaignDto } from './campaign-form.utils'

export const CampaignsCreateStepForm = () => {
  const history = useHistory()
  const combos = useCombos(['gremios'])
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const { createCampaign, tableSelection, setSelectedTableRows } = useCampaignsContext()
  const fm = (step, id) => formatMessage({ id: `pages.campaigns.errors.step.${step}.error.${id}` })
  const [formState, setFormState] = useState({
    activa: true,
    financiacion: '01',
    origen: 'instalador',
    is_op: false,
    tiempograbacion: 0,
    tipo_gas: [],
    campanas_compatibles: [],
  })

  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [currentStep, setCurrentStep] = useState(1)
  const [tableSelectionReseted, setTableSelectionReseted] = useState(false)

  // const eraseGremiosAndProvsFields = (e) => {
  //   delete formState.comunidades
  //   formState.is_op = false
  //   setFormState({ ...formState, [e.target.name]: e.target.value })
  // }

  const handleChangeForm = (e) => {
    // if (e.target.name === 'origen') {
    //   eraseGremiosAndProvsFields(e)
    // } else {
    setFormState({ ...formState, [e.target.name]: e.target.value })
    // }
  }

  const computeDate = (date, days) => {
    if (!date) return
    const daysValue = parseInt(days || '0')
    const result = new Date(date)
    result.setDate(result.getDate() + daysValue)
    return result
  }

  function isSolapado(cr1_desde, cr1_hasta, cr2_desde, cr2_hasta) {
    if (!cr1_hasta) cr1_hasta = Number.MAX_SAFE_INTEGER
    if (!cr2_hasta) cr2_hasta = Number.MAX_SAFE_INTEGER
    return (
      cr1_desde === cr2_desde ||
      cr1_hasta === cr2_hasta ||
      (cr1_desde < cr2_desde && cr1_hasta >= cr2_desde) ||
      (cr2_desde < cr1_desde && cr2_hasta >= cr1_desde)
    )
  }

  function checkSolapamientosConceptosRetributivos() {
    //comprobar si dos conceptos retributivos con la misma id de concepto se solapan en alguno de sus rangos
    const conceptosRetributivos = formState.conceptos_retributivos
    for (let i = 0; i < conceptosRetributivos.length; i++) {
      const cr1 = conceptosRetributivos[i]
      for (let j = 0; j < conceptosRetributivos.length; j++) {
        const cr2 = conceptosRetributivos[j]
        if (i !== j) {
          if (cr1.concepto_retributivo_id == cr2.concepto_retributivo_id) {
            if (cr1.hasRangoUsuariosFinca) {
              if (
                isSolapado(
                  parseInt(cr1.usuarios_finca_desde),
                  parseInt(cr1.usuarios_finca_hasta),
                  parseInt(cr2.usuarios_finca_desde),
                  parseInt(cr2.usuarios_finca_hasta)
                )
              )
                return true
            }
            if (cr1.hasRangoPuestaServicio) {
              if (
                isSolapado(
                  parseInt(cr1.puesta_servicio_desde),
                  parseInt(cr1.puesta_servicio_hasta),
                  parseInt(cr2.puesta_servicio_desde),
                  parseInt(cr2.puesta_servicio_hasta)
                )
              )
                return true
            }
            if (cr1.hasRangoNumCaptaciones) {
              if (
                isSolapado(
                  parseInt(cr1.num_captaciones_desde),
                  parseInt(cr1.num_captaciones_hasta),
                  parseInt(cr2.num_captaciones_desde),
                  parseInt(cr2.num_captaciones_hasta)
                )
              )
                return true
            }
            if (cr1.hasRangoSaturacion) {
              if (
                isSolapado(
                  parseInt(cr1.saturacion_desde),
                  parseInt(cr1.saturacion_hasta),
                  parseInt(cr2.saturacion_desde),
                  parseInt(cr2.saturacion_hasta)
                )
              )
                return true
            }
          }
        }
      }
    }
    return false
  }

  function checkRangosCorrectos(concepto) {
    if (concepto.hasRangoUsuariosFinca) {
      let usuarios_finca_hasta = concepto.usuarios_finca_hasta
        ? parseInt(concepto.usuarios_finca_hasta)
        : Number.MAX_SAFE_INTEGER
      if (concepto.usuarios_finca_desde > usuarios_finca_hasta) {
        return false
      }
    }
    if (concepto.hasRangoPuestaServicio) {
      let puesta_servicio_hasta = concepto.puesta_servicio_hasta
        ? parseInt(concepto.puesta_servicio_hasta)
        : Number.MAX_SAFE_INTEGER
      if (concepto.puesta_servicio_desde > puesta_servicio_hasta) {
        return false
      }
    }
    if (concepto.hasRangoNumCaptaciones) {
      let num_captaciones_hasta = concepto.num_captaciones_hasta
        ? parseInt(concepto.num_captaciones_hasta)
        : Number.MAX_SAFE_INTEGER
      if (concepto.num_captaciones_desde > num_captaciones_hasta) {
        return false
      }
    }
    if (concepto.hasRangoSaturacion) {
      let saturacion_hasta = concepto.saturacion_hasta
        ? parseInt(concepto.saturacion_hasta)
        : Number.MAX_SAFE_INTEGER
      if (concepto.saturacion_desde > saturacion_hasta) {
        return false
      }
    }
    return true
  }

  const handleVerifyStep = () => {
    let errors
    if (currentStep === 1) {
      if (!formState.nombre) {
        errors = fm(currentStep, 1)
      } else if (!formState.conceptos_retributivos) {
        errors = fm(currentStep, 2)
      } else if (formState.financiacion === '01') {
        if (!formState.limite_financiacion) {
          errors = fm(currentStep, 7)
        }
        if (!formState.meses_financiacion) {
          errors = fm(currentStep, 6)
        }
      } else if (formState.conceptos_retributivos.length > 0) {
        formState.conceptos_retributivos.forEach((concepto) => {
          if (!concepto.importe) {
            errors = fm(currentStep, 3)
            return
          }
          if (concepto.hasRangoUsuariosFinca && !concepto.usuarios_finca_desde) {
            errors = fm(currentStep, 4)
            return
          }
          if (concepto.hasRangoPuestaServicio && !concepto.puesta_servicio_desde) {
            errors = fm(currentStep, 8)
            return
          }
          if (concepto.hasRangoNumCaptaciones && !concepto.num_captaciones_desde) {
            errors = fm(currentStep, 9)
            return
          }
          if (concepto.hasRangoSaturacion && !concepto.saturacion_desde) {
            errors = fm(currentStep, 5)
            return
          }
          if (!checkRangosCorrectos(concepto)) {
            errors = fm(currentStep, 11)
            return
          }
        })
        if (formState.conceptos_retributivos.length > 1) {
          if (checkSolapamientosConceptosRetributivos()) {
            errors = fm(currentStep, 10)
          }
        }
      }
    }
    if (currentStep === 2) {
      if (!formState.tipo_vivienda) {
        errors = fm(currentStep, 1)
      }
      if (!formState.mercados?.length) {
        errors = fm(currentStep, 11)
      } else if (
        !formState.tarifas?.length &&
        formState.mercados?.some(
          (mercado) =>
            mercado.nombre === 'Pyme' ||
            mercado.nombre === 'PYME' ||
            mercado.nombre === 'Gran Consumo' ||
            mercado.nombre === 'GC' ||
            mercado.nombre === 'Caldera Centralizada' ||
            mercado.nombre === 'CC'
        )
      ) {
        errors = fm(currentStep, 10)
      } else if (
        !formState.elementos?.length &&
        !formState.mercados?.some(
          (mercado) =>
            mercado.nombre === 'Pyme' ||
            mercado.nombre === 'PYME' ||
            mercado.nombre === 'Gran Consumo' ||
            mercado.nombre === 'GC' ||
            mercado.nombre === 'Caldera Centralizada' ||
            mercado.nombre === 'CC'
        )
      ) {
        errors = fm(currentStep, 2)
      } else if (!formState.fechafinvigencia || !formState.fechainicio) {
        errors = fm(currentStep, 3)
      } else if (
        new Date(formState.fechainicio).getTime() >
        computeDate(formState.fechafinvigencia, formState.tiempograbacion)?.getTime()
      ) {
        errors = fm(currentStep, 5)
      }
    }
    if (currentStep === 3) {
      if (formState.tipo_gas.length < 1) {
        errors = fm(currentStep, 1)
      }
    }

    if (currentStep === 5) {
      if (!formState.comunidades?.length) {
        errors = fm(currentStep, 1)
      } else if (
        formState.comunidades.length > 0 &&
        formState.comunidades.some((ca) => !ca.cps?.length > 0)
      ) {
        errors = fm(currentStep, 2)
      } else if (
        formState.comunidades?.length > 0 &&
        formState.comunidades.some(
          (ca) => !ca.d_condiciones_economicas && !ca.d_condiciones_economicas_nuevo
        )
      ) {
        errors = fm(currentStep, 3)
      }
    }
    if (errors) {
      setErrorMssg(errors)
      window.scrollTo({ top: 0, left: 0 })
    } else {
      setErrorMssg(undefined)
      if (currentStep !== 5) {
        setCurrentStep(currentStep + 1)
        window.scrollTo({ top: 0, left: 0 })
      }
    }
    if (currentStep === 5 && errors) {
      return true
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = handleVerifyStep()
    if (!errors) {
      setLoading(true)
      createCampaign(prepareCampaignDto(formState))
        .then((data) => {
          setLoading(false)
          history.push(`/campaigns/${data.id}`)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: false,
  })

  useEffect(() => {
    if (!tableSelectionReseted) {
      setSelectedTableRows([])
      setTableSelectionReseted(true)
    } else {
      setFormState({ ...formState, campanas_compatibles: tableSelection })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSelection])

  return (
    <form onSubmit={handleSubmit}>
      {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
      <Box paddingX={'3em'}>
        <MultiStepForm activeStep={currentStep}>
          <Step label="Datos generales">
            <Box paddingTop={2}>
              <GridForm {...{ grid: STEP_1_FORM_CONFIG, inputs: INPUTS }} />
            </Box>
          </Step>
          <Step label="Datos campaña">
            <Box paddingTop={2}>
              <GridForm {...{ grid: STEP_2_FORM_CONFIG, inputs: INPUTS }} />
            </Box>
          </Step>
          <Step label="Datos vivienda">
            <Box paddingTop={2}>
              <GridForm {...{ grid: STEP_3_FORM_CONFIG, inputs: INPUTS }} />
            </Box>
          </Step>
          <Step label="Compatibilidad">
            <Box paddingTop={2}>
              <GridForm {...{ grid: STEP_4_FORM_CONFIG, inputs: INPUTS }} />
            </Box>
          </Step>
          <Step label="Actuación">
            <Box paddingTop={2}></Box>
            <GridForm {...{ grid: STEP_5_FORM_CONFIG, inputs: INPUTS }} />
          </Step>
        </MultiStepForm>
      </Box>
      <Box display={'flex'} justifyContent={currentStep > 1 ? 'space-between' : 'flex-end'}>
        {currentStep > 1 && (
          <Box>
            <Button variant="text" color="primary" onClick={() => setCurrentStep(currentStep - 1)}>
              {intl.formatMessage({
                id: `pages.campaigns.previous.step.button.label`,
              })}
            </Button>
          </Box>
        )}
        {currentStep !== 5 ? (
          <Box>
            <Button variant="text" color="primary" onClick={() => handleVerifyStep()}>
              {intl.formatMessage({
                id: `pages.campaigns.next.step.button.label`,
              })}
            </Button>
          </Box>
        ) : (
          <ThemeButton type="submit" color="primary" loading={loading}>
            {intl.formatMessage({
              id: `pages.campaigns.create.button.save.label`,
            })}
          </ThemeButton>
        )}
      </Box>
    </form>
  )
}
