import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MultiStepForm, Step } from 'react-multi-form-dark-blue'
import { ThemeButton } from '../../../../ui'
import {
  INPUTS_CONFIG,
  STEP_1_FORM_CONFIG,
  STEP_2_FORM_CONFIG,
  STEP_3_FORM_CONFIG,
  STEP_4_FORM_CONFIG,
} from './public-offer-form.constants'
import { GridForm } from './grid-form.component'
import { usePublicOfferContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'
import { usePublicOfferValidations } from '../../hooks'
import { preparePublicOfferDto } from './public-offer-form.utils'
import { CloneBtn } from '../buttons'

export const PublicOfferEditStepForm = () => {
  // const permissions = useSelector((state) => state.global.permissions)
  const combos = useCombos(['gremios'])
  const history = useHistory()
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const { createPublicOffer, publicOffer, editPublicOffer } = usePublicOfferContext()
  // const { formErrors } = usePublicOfferValidations()
  const fm = (step, id) => formatMessage({ id: `pages.campaigns.errors.step.${step}.error.${id}` })
  const [formState, setFormState] = useState({
    // activa: true,
    // financiacion: '00',
    // origen: 'instalador',
    // is_op: true,
    // tiempograbacion: 0,
    // tipo_gas: [],
    ...publicOffer,
    financiacion: publicOffer.financiacion ? '01' : '00',
    is_op: true,
  })
  const [preEditFormState, setPreEditFromState] = useState({
    ...publicOffer,
    financiacion: publicOffer.financiacion ? '01' : '00',
  })
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [currentStep, setCurrentStep] = useState(1)

  const eraseGremiosAndProvsFields = (e) => {
    delete formState.comunidades
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleChangeForm = (e) => {
    if (e.target.name === 'origen') {
      eraseGremiosAndProvsFields(e)
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const computeDate = (date, days) => {
    if (!date) return
    const daysValue = parseInt(days || '0')
    const result = new Date(date)
    result.setDate(result.getDate() + daysValue)
    return result
  }

  const handleVerifyStep = (event) => {
    let errors
    if (editMode) {
      if (currentStep === 1) {
        if (!formState.nombre) {
          errors = fm(currentStep, 1)
        } else if (!formState.conceptos_retributivos) {
          errors = fm(currentStep, 2)
        } else if (formState.financiacion === '01') {
          if (!formState.limite_financiacion) {
            errors = fm(currentStep, 7)
          }
          if (!formState.meses_financiacion) {
            errors = fm(currentStep, 6)
          }
        } else if (formState.conceptos_retributivos.length > 0) {
          formState.conceptos_retributivos.forEach((concepto) => {
            if (!concepto.importe) {
              errors = fm(currentStep, 3)
              return
            }
            if (concepto.hasRangoUsuariosFinca && !concepto.usuarios_finca_desde) {
              errors = fm(currentStep, 4)
              return
            }
            if (concepto.hasRangoPuestaServicio && !concepto.puesta_servicio_desde) {
              errors = fm(currentStep, 8)
              return
            }
            if (concepto.hasRangoNumCaptaciones && !concepto.num_captaciones_desde) {
              errors = fm(currentStep, 9)
              return
            }
            if (concepto.hasRangoSaturacion && !concepto.saturacion_desde) {
              errors = fm(currentStep, 5)
              return
            }
          })
        }
      }
      if (currentStep === 2) {
        if (!formState.tipo_vivienda) {
          errors = fm(currentStep, 1)
        }
        if (!formState.mercados?.length) {
          errors = fm(currentStep, 11)
        } else if (
          !formState.tarifas?.length &&
          formState.mercados?.some(
            (mercado) =>
              mercado.nombre === 'Pyme' ||
              mercado.nombre === 'PYME' ||
              mercado.nombre === 'Gran Consumo' ||
              mercado.nombre === 'GC' ||
              mercado.nombre === 'Caldera Centralizada' ||
              mercado.nombre === 'CC'
          )
        ) {
          errors = fm(currentStep, 10)
        } else if (
          !formState.elementos?.length &&
          !formState.mercados?.some(
            (mercado) =>
              mercado.nombre === 'Pyme' ||
              mercado.nombre === 'PYME' ||
              mercado.nombre === 'Gran Consumo' ||
              mercado.nombre === 'GC' ||
              mercado.nombre === 'Caldera Centralizada' ||
              mercado.nombre === 'CC'
          )
        ) {
          errors = fm(currentStep, 2)
        } else if (!formState.fechafinvigencia || !formState.fechainicio) {
          errors = fm(currentStep, 3)
        } else if (
          new Date(formState.fechainicio).getTime() >
          computeDate(formState.fechafinvigencia, formState.tiempograbacion)?.getTime()
        ) {
          errors = fm(currentStep, 5)
        }
      }
      if (currentStep === 3) {
        if (formState.tipo_gas.length < 1) {
          errors = fm(currentStep, 1)
        }
      }

      if (currentStep === 4) {
        if (!formState.comunidades?.length) {
          errors = fm(currentStep, 1)
        } else if (
          formState.comunidades.length > 0 &&
          formState.comunidades.some((ca) => !ca.cps?.length > 0)
        ) {
          errors = fm(currentStep, 2)
        } else if (
          formState.comunidades?.length > 0 &&
          formState.comunidades.some(
            (ca) => !ca.d_condiciones_economicas && !ca.d_condiciones_economicas_nuevo
          )
        ) {
          errors = fm(currentStep, 3)
        }
      }
    } else {
      errors = false
    }
    if (errors) {
      setErrorMssg(errors)
      window.scrollTo({ top: 0, left: 0 })
    } else {
      setErrorMssg(undefined)
      if (currentStep !== 4 && event !== 'saveEdit') {
        setCurrentStep(currentStep + 1)
        window.scrollTo({ top: 0, left: 0 })
      }
    }
    if (currentStep === 4 && errors) {
      return true
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (editMode) {
      const errors = handleVerifyStep('saveEdit')
      if (!errors) {
        setLoading(true)
        editPublicOffer(preparePublicOfferDto(formState))
          .then((data) => {
            setEditMode(false)
            setFormState({
              ...data,
              financiacion: publicOffer.financiacion ? '01' : '00',
              is_op: true,
            })
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
    } else {
      setEditMode(true)
    }
  }

  const handleCancel = () => {
    setErrorMssg(false)
    setFormState({ ...preEditFormState })
    setEditMode(false)
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: !editMode,
  })

  return (
    <>
      {formState.codigo && (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent={'center'} alignItems="center" mb={5}>
            <Box display="flex" justifyContent="flex-start" flex={1}>
              <CloneBtn {...{ disabled: editMode }} />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              {editMode ? (
                <Box mr={1}>
                  <ThemeButton color="default" onClick={handleCancel} disabled={loading}>
                    {intl.formatMessage({ id: 'global.cancel' })}
                  </ThemeButton>
                </Box>
              ) : null}
              <ThemeButton onClick={handleSubmit} color="primary" loading={loading}>
                {intl.formatMessage({
                  id: `pages.campaigns.detail.button.${editMode ? 'save' : 'edit'}.label`,
                })}
              </ThemeButton>
            </Box>
          </Box>
          {errorMssg ? (
            <Box mb={5}>
              <Alert severity="error">{errorMssg}</Alert>
            </Box>
          ) : null}
          <Box paddingX={'3em'}>
            <MultiStepForm activeStep={currentStep}>
              <Step label="Datos generales">
                <Box paddingTop={2}>
                  <GridForm {...{ grid: STEP_1_FORM_CONFIG, inputs: INPUTS }} />
                </Box>
              </Step>
              <Step label="Datos campaña">
                <Box paddingTop={2}>
                  <GridForm {...{ grid: STEP_2_FORM_CONFIG, inputs: INPUTS }} />
                </Box>
              </Step>
              <Step label="Datos vivienda">
                <Box paddingTop={2}>
                  <GridForm {...{ grid: STEP_3_FORM_CONFIG, inputs: INPUTS }} />
                </Box>
              </Step>
              <Step label="Actuación">
                <Box paddingTop={2}></Box>
                <GridForm {...{ grid: STEP_4_FORM_CONFIG, inputs: INPUTS }} />
              </Step>
            </MultiStepForm>
          </Box>
          <Box display={'flex'} justifyContent={currentStep > 1 ? 'space-between' : 'flex-end'}>
            {currentStep > 1 && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  {intl.formatMessage({
                    id: `pages.campaigns.previous.step.button.label`,
                  })}
                </Button>
              </Box>
            )}
            {currentStep !== 4 ? (
              <Box>
                <Button variant="text" color="primary" onClick={() => handleVerifyStep()}>
                  {intl.formatMessage({
                    id: `pages.campaigns.next.step.button.label`,
                  })}
                </Button>
              </Box>
            ) : (
              <ThemeButton type="submit" color="primary" loading={loading}>
                {intl.formatMessage({
                  id: `pages.campaigns.create.button.save.label`,
                })}
              </ThemeButton>
            )}
          </Box>
        </form>
      )}
    </>
  )
}
