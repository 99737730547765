import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Paper, Tooltip } from "@material-ui/core";
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'
import { STUCK_TABLE_COLUMNS } from '../../constants'
import { stuckDataPreparation } from '../../logics'
import { SendToZeusCheckbox } from './send-to-zeus-checkbox.component'
import { stuckTableStyles } from './stuck-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const StuckTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const stuckLoader = useSelector((state) => state.loader.stuck)
  const combos = useSelector((state) => state.combos)


  const data = stuckLoader?.data?.solicitudes || []
  const totalEntries = stuckLoader?.data?.total_solicitudes
  const search = stuckLoader?.config?.params
  const loading = stuckLoader?.loading

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    return STUCK_TABLE_COLUMNS.map((column) => {
      if (column.field === 'pez')
        return {
          ...column,
          title: 'Envío programado',
        }
      return {
        ...column,
        title: formatMessage({ id: `pages.stuck.table.columns.${column.field}.title` }),
        cellStyle: {
          ...stuckTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }
    })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Assembling and adjusting data
  const processedData = useMemo(() => {
    return stuckDataPreparation(data, { intl })
  }, [data, intl])

  //Table configuration
  const finalTableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: stuckTableStyles.header,
      draggable: false,
      fixedColumns: {
        right: 1,
      },
    }),
    []
  )

  //Function to build table parts
  const getFormattedCell = (props) => {
    if (props.columnDef.field === 'solicitud_id') {
      return <a style={{color: "#004a6b", textDecoration:"none"}} href={`#!/applications/${props.rowData.id}`}>{props.rowData.solicitud_id}</a>
    }
    if (props.columnDef.field === 'descripcion') {
      //On hover, show the full description in list format (splitted by ", ") in a tooltip
      const description_array = props.rowData.descripcion.split(', ')
      let description = () => {
        return (
          <>
            Se han detectado los siguientes errores:
            <ul style={{paddingLeft:"20px"}}>
              {description_array.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </>
        );
      };

      return (
        <Tooltip title={description()} placement="bottom-start" interactive>
          <Box
            style={{
              maxWidth: '100%',
overflow: 'hidden',
textOverflow: 'ellipsis',
whiteSpace: 'nowrap',
              paddingRight: '1em',
            }}
          >
            {props.rowData.descripcion}
          </Box>
        </Tooltip>
      )
    }
    if (props.columnDef.field === 'pez') {
      return <SendToZeusCheckbox {...props} />
    }
    return props.children
  }
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        if (props.columnDef.field === 'solicitud_id' || props.columnDef.field === 'descripcion') {
          return (
            <MTableCell {...props} style={{ padding: 0 }} value="">
              {getFormattedCell(props)}
            </MTableCell>
          )
        }
        else {
          return(
          <MTableCell {...props} style={{ padding: 0 }} >
            {getFormattedCell(props)}
          </MTableCell>
          )
        }
      }
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={finalTableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
    />
  )
}
