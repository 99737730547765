import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { useConceptosRetributivosCalls } from './conceptos-retributivos-calls.hook'
import { conceptosRetributivosReducer } from '../reducers'
import {
  CONCEPTOS_RETRIBUTIVOS_INITIAL_STATE,
  CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES,
} from '../reducers'

import { useCombos, useManualCombo } from '../../../features/combos'

export const useConceptosRetributivosReducer = () => {
  const { setManualCombo } = useManualCombo()

  const [state, dispatch] = useReducer(
    conceptosRetributivosReducer,
    CONCEPTOS_RETRIBUTIVOS_INITIAL_STATE
  )
  const history = useHistory()
  const {
    getConceptosRetributivosCall,
    getConceptoRetributivoCall,
    putConceptoRetributivoCall,
    postConceptoRetributivoCall,
    deleteConceptoRetributivoCall,
  } = useConceptosRetributivosCalls()

  const resetConceptoRetributivoState = () => {
    dispatch({
      type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_CONCEPTO_RETRIBUTIVO,
      payload: CONCEPTOS_RETRIBUTIVOS_INITIAL_STATE,
    })
  }

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_LOADING,
      })
      getConceptosRetributivosCall(config)
        .then(({ data }) => {
          dispatch({
            type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_conceptos_retributivos: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getConceptoRetributivo = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getConceptoRetributivoCall(id)
          .then(({ data }) => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_CONCEPTO_RETRIBUTIVO,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const editConceptoRetributivo = useCallback(
    (payload) => {
      const { id, ...restPayload } = payload
      return new Promise((resolve, reject) => {
        putConceptoRetributivoCall(id, restPayload)
          .then(({ data }) => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_CONCEPTO_RETRIBUTIVO,
              payload: data,
            })
            return data
          })
          .then((data) => {
            setManualCombo('conceptos_retributivos', data)
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const createConceptoRetributivo = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        postConceptoRetributivoCall({ ...payload })
          .then(({ data }) => {
            setManualCombo('conceptos_retributivos', data)
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/conceptos-retributivos/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  const deleteConceptoRetributivo = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        deleteConceptoRetributivoCall(id)
          .then(({ data }) => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_CONCEPTO_RETRIBUTIVO,
              payload: data,
            })
          })
          .then(() => getTableData())
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: CONCEPTOS_RETRIBUTIVOS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    setSelectedTableRows,
    onClickTableRow,
    getTableData,
    getConceptoRetributivo,
    editConceptoRetributivo,
    createConceptoRetributivo,
    deleteConceptoRetributivo,
    resetConceptoRetributivoState,
    ...state,
  }
}
