import React, { useMemo } from 'react'
import {
  Paper,
  Box,
  InputAdornment,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'

import { TextInput } from '..'

//Basic default functionality
const defaultRenderElement = (element, chosen, keyField, nameField) => {
  return (
    <ListItem key={element[keyField]}>
      <FormControlLabel
        label={element[nameField]}
        control={<Checkbox checked={chosen} disableRipple />}
      />
    </ListItem>
  )
}

const defaultRenderContainer = ({ children }) => (
  <List style={{ height: '350px', overflowY: 'scroll' }}>{children}</List>
)

const defaultFilter = (element, filter, nameField) => {
  return element[nameField].toUpperCase().indexOf(filter) > -1
}

//Component Definition:
export const PostalCodeCheckList = ({
  className,
  //Main input:
  keyField = 'key',
  nameField = 'name',
  elements = [],
  selected = [],
  funcRenderElement = defaultRenderElement,
  funcRenderContainer = defaultRenderContainer,
  //Optional Search box functionality
  searchBox,
  searchValue,
  searchPlaceholder = '',
  searchIcon = Search,
  funcSearchFilter = defaultFilter,
  funcSearchOnChange = (event) => {},
  //Optional Whole Selection checkbox functionality
  wholeSelector,
  wholeCaption = 'ALL',
  funcWholeOnChange = (event, keys) => {},
}) => {
  //List to display: we filter element's nameField according to searchValue and render according to the given function
  const [elementsDisplay, elementsChosen, renderedList] = useMemo(() => {
    const elementsChosen = []
    const renderedList = []
    const elementsDisplay = []
    elements.forEach((province) => {
      const filteredMunicipios = searchValue ? [] : province.municipios
      province.municipios.forEach((municipality) => {
        const filteredCps = []
        municipality.cps.forEach((cp) => {
          let isCpMatch
          if (searchValue) {
            isCpMatch = funcSearchFilter(cp, searchValue, nameField)
          }
          if (isCpMatch) {
            filteredCps.push(cp)
          }
        })
        if (filteredCps.length > 0) {
          filteredMunicipios.push({ ...municipality, cps: filteredCps })
        }
      })
      const filteredProvince = { ...province, municipios: filteredMunicipios }
      const chosen = selected.indexOf(province[keyField]) > -1
      if (chosen) {
        elementsChosen.push(province)
      }
      renderedList.push(funcRenderElement(filteredProvince, chosen, keyField, nameField))
      elementsDisplay.push(province[keyField])
      return
    })
    return [elementsDisplay, elementsChosen, renderedList]
  }, [selected, searchValue, elements, nameField, keyField, funcSearchFilter, funcRenderElement])

  //List container component
  const MyContainer = funcRenderContainer

  //Search box:
  let searchBoxJSX = null
  if (searchBox) {
    const SearchIcon = searchIcon
    searchBoxJSX = (
      <Box p={3}>
        <TextInput
          name="_search"
          style={{ backgroundColor: 'white' }}
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={funcSearchOnChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ marginRight: '1em' }} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }

  //Whole selector:
  let wholeSelectorJSX = null
  if (wholeSelector) {
    const wholeNum = elementsDisplay.length
    const wholeChosen = elementsChosen.length
    const isWhole = wholeNum === wholeChosen
    wholeSelectorJSX = (
      <Box p={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isWhole}
              indeterminate={wholeChosen > 0 && !isWhole}
              onChange={(event) => funcWholeOnChange(event, elementsDisplay)}
              disableRipple
            />
          }
          label={wholeCaption + ' (' + wholeNum + ')'}
        />
        <hr />
      </Box>
    )
  }

  //Full component:
  return (
    <Paper elevation={2} className={className}>
      {searchBoxJSX}
      {wholeSelectorJSX}
      <MyContainer>{renderedList}</MyContainer>
    </Paper>
  )
}

export default PostalCodeCheckList
