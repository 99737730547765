import React, { useMemo, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { ThemeButton, TextInput } from '../../../../ui'
import { withStyles, Tooltip, Icon, Typography, Box } from '@material-ui/core'
import { conceptosRetributivosTableStyles } from './conceptos-retributivos-table.styles'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'
import {
  CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS,
  DEFAULT_QUERY,
} from './conceptos-retributivos-table.constants'
import { useCampaignsContext } from '../../../../features/campaigns/contexts'
import { campaignsDataPreparation } from '../../../../features/campaigns/logics'
import { CampaignStateSwitch } from '../../../../features/campaigns/components/table-actions'
import { CampaignsFilter } from '../../../../features/campaigns'
import { current } from '@reduxjs/toolkit'
const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const ConceptosRetributivosCustomTable = ({ readOnly, codigo, conceptosRetributivos }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const combos = useSelector((state) => state.combos)
  // const {
  //   data,
  //   pagination: { total_campanas: totalEntries },
  //   loading,
  //   search,
  //   tableSelection,
  //   // onClickTableRow,
  //   setSelectedTableRows,
  //   getCampanasCompatiblesTableData,
  // } = useCampaignsContext()
  // const handleSelectRows = (rows, row) => {
  //   if (readOnly) return
  //   setSelectedTableRows(rows)
  // } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...DEFAULT_QUERY,
      ...urlSearchParams,
    }
  }, [conceptosRetributivos, location.search])

  const formattedColumns = useMemo(
    () =>
      CONCEPTOS_RETRIBUTIVOS_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({
            id: `pages.conceptos.retributivos.table.columns.${column.field}.title`,
          }),
          cellStyle: {
            ...conceptosRetributivosTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
            maxWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  // const processedData = useMemo(() => {
  //   const selectedIds = tableSelection.map((el) => el.id)
  //   return campaignsDataPreparation({ data, combos, intl }).map((row) => {
  //     if (selectedIds.indexOf(row.id) !== -1) {
  //       if (!row.tableData) row.tableData = {}
  //       row.tableData['checked'] = true
  //     }
  //     return row
  //   })
  // }, [data, combos, tableSelection]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = {
    toolbar: false,
    selection: false,
    showSelectAllCheckbox: false,
    headerStyle: conceptosRetributivosTableStyles.header,
    tableLayout: 'fixed',
    draggable: false,
  }
  // eslint-disable-line react-hooks/exhaustive-deps

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'state' ? <CampaignStateSwitch {...props} /> : null}
        </MTableCell>
      ),
    }),
    []
  )
  // const handleRowClick = useCallback((_, row) => {
  // onClickTableRow(row['id'])
  // }, [])
  const formattedConceptosRetributivosData = useMemo(() => {
    let formattedConceptosRetributivos = conceptosRetributivos.map((concepto) => {
      return {
        ...concepto,
        usuarios_finca_desde:
          concepto.usuarios_finca_desde && concepto.usuarios_finca_hasta
            ? `${concepto.usuarios_finca_desde}-${concepto.usuarios_finca_hasta}`
            : concepto.usuarios_finca_desde,
        puesta_servicio_desde:
          concepto.puesta_servicio_desde && concepto.puesta_servicio_hasta
            ? `${concepto.puesta_servicio_desde}-${concepto.puesta_servicio_hasta}`
            : concepto.puesta_servicio_desde,
        num_captaciones_desde:
          concepto.num_captaciones_desde && concepto.num_captaciones_hasta
            ? `${concepto.num_captaciones_desde}-${concepto.num_captaciones_hasta}`
            : concepto.num_captaciones_desde,
        saturacion_desde:
          concepto.saturacion_desde && concepto.saturacion_hasta
            ? `${concepto.saturacion_desde}-${concepto.saturacion_hasta}`
            : concepto.saturacion_desde,
      }
    })
    return formattedConceptosRetributivos
  }, [conceptosRetributivos])

  return (
    <TableUrlPagination
      columns={formattedColumns}
      // components={tableComponents}
      options={tableOptions}
      totalEntries={conceptosRetributivos.length}
      // search={search}
      data={formattedConceptosRetributivosData}
      // isLoading={loading}
      // onRowClick={handleRowClick}
      // onSelectionChange={handleSelectRows}
    />
  )
}

export const ConceptosRetributivos = withStyles(conceptosRetributivosTableStyles)(
  ({
    classes,
    readOnly = true,
    value = '',
    decimalScale = undefined,
    fixedDecimalScale = false,
    maxValue = 0,
    units = null,
    codigo,
    conceptosRetributivos,
    ...props
  }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'
    const tooltip = useMemo(() => {
      return props.tooltip ? (
        <span style={{ fontSize: 16, marginLeft: 4 }}>
          <Tooltip
            placement="top"
            title={
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {props.tooltip}
              </Typography>
            }
          >
            <Icon fontSize="inherit">help_outline</Icon>
          </Tooltip>
        </span>
      ) : null
    }, [props.tooltip])

    return (
      <>
        {/* {props.title ? (
          <Typography
            variant={`subtitle${typographyVariant}`}
            color="textSecondary"
            className={classes.title}
          >
            {props.title + (tooltip ? tooltip : '')}
          </Typography>
        ) : null} */}
        <Box flex={1}>
          {/* <Box mb={1}>
            <CampaignsFilter isCampanasCompatibles={true} />
          </Box> */}
          <ConceptosRetributivosCustomTable
            readOnly={readOnly}
            codigo={codigo}
            conceptosRetributivos={conceptosRetributivos}
          />
        </Box>
      </>
    )
  }
)

export const ConceptosRetributivosTable = ({ codigo, conceptosRetributivos }) => {
  const { formatMessage } = useIntl()
  return (
    <Box flex={1} mb={2} mt={3}>
      <ConceptosRetributivos codigo={codigo} conceptosRetributivos={conceptosRetributivos} />
    </Box>
  )
}
