import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setCombosByIds, setCombo } from '../../combos/slices'
import { useCombosCalls } from './combos-calls.hook'

export const useCombos = (comboIds, comboIdsToSort = []) => {
  const dispatch = useDispatch()
  const combos = useSelector((state) => state.combos)

  useEffect(() => {
    const comboIdsArray = []
    comboIds.forEach((comboId) => {
      const order = comboIdsToSort.indexOf(comboId) > -1
      if (!combos[comboId] || comboId === 'conceptos_retributivos') {
        comboIdsArray.push({ comboId, order })
      }
    })
    if (comboIdsArray.length) {
      dispatch(setCombosByIds(comboIdsArray))
    }
  }, [comboIds]) // eslint-disable-line react-hooks/exhaustive-deps

  return combos
}

export const useManualCombo = () => {
  const dispatch = useDispatch()
  return {
    setManualCombo: (id, data) => dispatch(setCombo({ comboId: id, data })),
  }
}

export const useCustomCombo = (customCombos = []) => {
  const { getEmpresasCustomCombo, getGremiosCustomCombo, getManagers } = useCombosCalls()
  const { getGremiosIPCustomCombo } = useCombosCalls()
  const dispatch = useDispatch()
  const combos = useSelector((state) => state.combos)
  const intl = useIntl()

  useEffect(() => {
    customCombos.forEach((comboId) => {
      if (!combos[comboId]) {
        setCustomCombos(comboId)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setCustomCombos = (comboId) => {
    switch (comboId) {
      case 'empresas':
        getEmpresasCustomCombo()
          .then((data) => {
            dispatch(setCombo({ comboId: 'empresas', data }))
          })
          .catch((response) =>
            console.error('Error recuperando custom combo', response && response.data)
          )
        break
      case 'gremios':
        getGremiosCustomCombo()
          .then((data) => {
            dispatch(setCombo({ comboId: 'gremios', data }))
          })
          .catch((response) =>
            console.error('Error recuperando custom combo', response && response.data)
          )
        break
      case 'gremios_ip':
        getGremiosIPCustomCombo()
          .then((data) => {
            dispatch(setCombo({ comboId: 'gremios_ip', data }))
          })
          .catch((response) =>
            console.error('Error recuperando custom combo', response && response.data)
          )
        break
      case 'alerts':
        dispatch(
          setCombo({
            comboId: 'alerts',
            data: ['alerta_contacto_instalador', 'alerta_aceptar_presupuesto_instalador'].map(
              (el) => ({
                key: el,
                value: intl.formatMessage({ id: `pages.applications.alerts.${el}.title` }),
              })
            ),
          })
        )
        break
      case 'gestores':
        getManagers()
          .then((data) => {
            dispatch(setCombo({ comboId: 'gestores', data }))
          })
          .catch((response) =>
            console.error('Error recuperando custom combo', response && response.data)
          )
        break
      default: {
        return console.debug('Custom combo not defined: ', comboId)
      }
    }
  }
}
